import {Component, OnInit} from '@angular/core';
import {AtlTrackingService} from '../../atl/atl-tracking.service';
import {TrackingPoints} from '../../atl/tracking-points';

@Component({
  selector: 'app-additional-information',
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.sass'],
})
export class AdditionalInformationComponent implements OnInit {

  constructor(private readonly atlTrackingService: AtlTrackingService) {
  }

  ngOnInit(): void {
    this.atlTrackingService.applyIfRouteValid(TrackingPoints.additionalInformation);
  }
}
