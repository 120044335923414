<div nxRow nxRowJustify="center">
  <div nxCol="11">
    <h3 class="nx-margin-bottom-xs"
        nxHeadline="subsection-small"
        [innerHTML]="headLine">
    </h3>
  </div>
  <div nxCol="1" class="u-padding-none">
    <nx-link>
      <a [routerLink]='route' [queryParams]="{contactID: queryParam}">
        <nx-icon name="pen"></nx-icon>
      </a>
    </nx-link>
  </div>
</div>
