import {Component, OnInit} from '@angular/core';
import {ProviderData} from '../../models/providerData';
import {AtlTrackingService} from '../../atl/atl-tracking.service';
import {TrackingPoints} from '../../atl/tracking-points';

@Component({
  selector: 'app-false-positive',
  templateUrl: './false-positive.component.html',
  styleUrls: ['./false-positive.component.sass']
})
export class FalsePositiveComponent implements OnInit {
  providerData: ProviderData;

  constructor(private readonly atlTrackingService: AtlTrackingService) {
  }

  ngOnInit(): void {
    this.atlTrackingService.applyIfRouteValid(TrackingPoints.falsePositive);
  }
}
