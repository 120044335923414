<div class="nx-margin-bottom-3xl">
  <div nxLayout="grid maxwidth">
    <app-card-header [showBackButton]="false"
                     [subTitle]="'confirmation.subtitle' | translate"
                     [title]="'confirmation.title' | translate">
    </app-card-header>

    <form *ngIf="formGroup" [formGroup]="formGroup">

      <div nxRow
           nxRowJustify="start,start,center"
           nxRowAlignItems="start">
        <div nxCol="12,12,8,6">
          <nx-formfield nxLabel="{{'contact.form.mobilenumber' | translate}}">
            <input type="tel"
                   nxInput
                   formControlName="phone"
                   id="cy-contact-telefon"/>
            <span nxFormfieldPrefix> +49 </span>
            <nx-icon
              name="info-circle-o"
              style="color: #007ab3"
              size="s"
              nxTooltip="{{'contact.form.mobilenumber_tooltip' | translate}}">
            </nx-icon>
            <nx-error
              nxFormfieldError>
              {{'contact.form.mobilenumber_warning' | translate}}
            </nx-error>
          </nx-formfield>
        </div>
      </div>

      <div nxRow
           nxRowJustify="start,start,center"
           nxRowAlignItems="start">
        <div nxCol="12,12,8,6">
          <div nxRow class="align-col" nxRowJustify="start">
            <div class="license-plate-header">
              <p class="u-text-align-left u-margin-right-15">{{'contact.form.licenseplate' | translate}}</p>
              <nx-icon
                name="info-circle-o"
                style="color: #007ab3"
                size="s"
                nxTooltip="{{'contact.form.licenseplate_tooltip' | translate}}">
              </nx-icon>
            </div>
          </div>
          <div nxRow nxRowJustify="start" class="nx-margin-bottom-s align-col">
            <div class="outer-license-plate">
              <div class="license-plate-wrapper">
                <img class="eu-symbol" alt="eu-license-plate"
                     src="/assets/licenseplate/eu_zeichen.svg">
                <input type="text"
                       class="city border"
                       placeholder="BSP"
                       [maxLength]="3"
                       formControlName="licensePlateFirst"
                       id="cy-license-plate-city">
                <img class="badge" alt="license-plate"
                     src="/assets/licenseplate/license_plate.svg">
                <input type="text"
                       placeholder="AB"
                       class="letters border"
                       [maxLength]="2"
                       formControlName="licensePlateSecond"
                       id="cy-license-plate-letters">
                <input type="text"
                       placeholder="1234"
                       class="numbers border"
                       [maxLength]="5"
                       formControlName="licensePlateThird"
                       id="cy-license-plate-numbers">
              </div>
            </div>
          </div>
          <div nxRow nxRowJustify="start" class="align-col">
            <nx-error *ngIf="isLicensePlateValid()" nxFormfieldError>
              {{'contact.form.validation' | translate}}
            </nx-error>
          </div>
        </div>
      </div>
      <app-card-buttons [forward]="'confirmation.confirmation' | translate"
                        (emitIsReadyToDrive)="goToRegistration()"
                        [disabled]='formGroup.invalid'>
      </app-card-buttons>

      <div nxRow nxRowJustify="start,start,center" nxRowAlignItems="start" class="u-margin-top-25">
        <div nxCol="12,12,8,6">
          <div nxRow class="align-col" nxRowJustify="start">
            <div class="license-plate-header">
              <div nxRow class="align-col" nxRowJustify="start">
                {{'contact.form.mandatory' | translate}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
