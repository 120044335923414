<div class="nx-margin-bottom-3xl">
  <div nxLayout="grid maxwidth">
    <app-card-header [showBackButton]="true"
                     [title]="'ready.title' | translate"
                     [subTitle]="'ready.subtitle' | translate">
    </app-card-header>
    <app-card-buttons [jumpEnabled]="true"
                      [jump]="'ready.jump' | translate"
                      [jumpRoute]="'ready.jumpRoute' | translate"
                      [forwardRoute]="'ready.forwardRoute' | translate"
                      [forward]="'ready.forward' | translate"
                      (emitIsReadyToDrive)="setReadyToDrive($event)"
                      [defaultNarrowWidthOnDesktop]="false">
    </app-card-buttons>
  </div>
</div>
