import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReadyToDriveService {

  private readonly isReadyToDrive = 'isReadyToDrive';

  constructor() {
  }

  get(): boolean {
    return localStorage.getItem(this.isReadyToDrive) !== 'undefined'
      ? JSON.parse(localStorage.getItem(this.isReadyToDrive))
      : undefined;
  }

  set(isReadyToDrive: boolean): void {
    localStorage.setItem(this.isReadyToDrive, JSON.stringify(isReadyToDrive));
  }
}
