import {Component, OnInit} from '@angular/core';
import {ProviderData} from '../../models/providerData';
import {select, Store} from '@ngrx/store';
import {providerData} from '../../selector/provider-data-selector';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.sass']
})
export class StartComponent implements OnInit {
  providerData$: Observable<ProviderData>;

  constructor(private readonly store: Store<any>) {
    this.providerData$ = this.store.pipe(select(providerData));
  }

  ngOnInit(): void {
  }

  showPersonalizedData(provider: string): boolean {
    return 'IMS' !== provider;
  }
}
