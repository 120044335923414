export interface IBusinessConfig {
  appId: string;
  targetGroup: string;
  tenant: string;
  processLocation?: string;
  processName?: string;
  processCategory?: string;
}

export class BusinessConfig implements IBusinessConfig {
  appId: string;
  targetGroup: string;
  tenant: string;
  processLocation?: string;
  processName?: string;
  processCategory?: string;

  defaultProcessLocation = '/hilfe-am-unfallort';
  defaultProcessName = 'AZD-Unfallassistent_accelerator-Selfservice';
  defaultProcessCategory = 'selfservice';

  constructor() {
    this.appId = this.defaultProcessName; // currently: appId == processName
    this.targetGroup = 'customer';
    this.tenant = 'AZDE';
  }

  get_page_pageInfo_pageName(pageName: string): string {
    return `${this.defaultProcessLocation}-${this.defaultProcessName}/${pageName}`;
  }

  // excerpt from allianz-tracking-lib.js:
  // you can set pageName only if processLocation is invalid string (not empty)
  // Example: "/qa/rechner/AZD-QaSpa-Selfservice/03 quote"
  // if (this.businessConfig.processLocation) {
  //   this.setValue('page.pageInfo.pageName', this.businessConfig.processLocation +
  //   '-' + this.getValue('process.processName') +
  //   '/' + this.getValue('process.stepNumber') +
  //   ' ' + this.getValue('process.stepName'));
  // }
  setProcessVars(): void {
    this.processLocation = this.defaultProcessLocation;
    this.processName = this.defaultProcessName;
    this.processCategory = this.defaultProcessCategory;
  }

  resetProcessVars(): void {
    this.processLocation = '';
    this.processName = '';
    this.processCategory = '';
  }
}
