<div class="nx-margin-bottom-3xl">
  <div nxLayout="grid maxwidth">
    <app-card-header [showBackButton]="true"
                     [subTitle]="'contactList.subTitle' | translate"
                     [title]="'contactList.title' | translate">
    </app-card-header>

    <div nxRow nxRowJustify="center" nxRowAlignItems="start" *ngIf="hasNoContacts()">
      <div [nxCol]="'12,12,6,4'">
        <nx-error>
          {{'contactList.error' | translate}}
        </nx-error>
      </div>
    </div>

    <div nxRow nxRowJustify="center" nxRowAlignItems="start">
      <div *ngFor="let contact of contacts; let i = index" [nxCol]="'12,12,6,4'">
        <nx-card class="u-full-width padding-16 height-on-desktop cursor-pointer"
                 (click)="editAccidentParty(i)"
                  [id]="'cy-edit-accident-party-' + i">
          <div (click)="removeContact(i)"
               class="remove-image cursor-pointer"
               [id]="'cy-remove-accident-party-' + i">
            <nx-icon name='close-circle' size="s"></nx-icon>
          </div>
          <div nxRow class="center">
            <div nxCol="2,2,12,12" class="text-align-center margin-bottom-12">
              <nx-icon name="check-circle" outline="false" size="m">
              </nx-icon>
            </div>
            <div nxCol="10,10,12,12" class="text-align-center">
              <div class="margin-bottom-12">
                <span class="font-size-20px green">
                  {{'contactList.involved' | translate}}
                </span>
                <span *ngIf="contacts.length > 1" class="font-size-20px green nx-margin-bottom-xs">
                  {{i + 1}}
                </span>
              </div>
              <span class="font-size-16px"
                    *ngIf="!contact.companySelected">{{contact.salutation}} {{contact.lastname}}</span>
              <span class="font-size-16px" *ngIf="contact.companySelected">{{contact.companydriver}}</span>
            </div>
          </div>
        </nx-card>
      </div>
    </div>
    <div nxRow nxRowJustify="start,start,center" nxRowAlignItems="start">
      <div [nxCol]="'12,12,6,4'">
        <nx-card class="u-full-width padding-8-26 cursor-pointer nx-margin-bottom-2xs" (click)="addAccidentParty()"
                 id="cy-add-accident-party">
          <div nxRow nxRowAlignItems="center">
            <div nxCol="2">
              <nx-icon name="product-plus" class="smaller" outline size="s">
              </nx-icon>
            </div>
            <div nxCol="10" class="padding-left-16">
              <span class="font-size-20px">
                {{'contactList.addInvolved' | translate}}
              </span>
              <br>
              <span class="font-size-16px">{{'contactList.optional' | translate}}</span>
            </div>
          </div>
        </nx-card>
      </div>
    </div>

    <app-card-buttons [forwardRoute]="getRoute('contactList.forwardRoute' | translate, 'contactList.summaryRoute' | translate)"
                      [forward]="'contactList.forward' | translate"
                      [disabled]="hasNoContacts()">
    </app-card-buttons>
  </div>
</div>
