import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {RegistrationData} from '../models/registrationData';
import {Params} from '@angular/router';

export interface RegistrationRequestData {
  mobileNumber: string;
  licensePlate: string;
  firstName: string;
  lastName: string;
}

export interface StellantisRegistrationRequestData extends RegistrationRequestData {
  consentConnectedCar: boolean;
  consentConnectedCarVersion: string;
  consentConnectedCarTs: number;
}

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private http: HttpClient) {
  }

  fetchRegistrationData(
    {
      token,
      licensePlateFirst,
      licensePlateSecond,
      licensePlateThird,
    }: Params
  ): Observable<RegistrationData> {
    // TODO response data should be called ContractData
    //  and not all specified fields are returned from the backend at the moment
    return this.http.post<RegistrationData>(
      `${environment.api.contractData}/${token.toLowerCase()}`,
      {
        licensePlate:
          `${licensePlateFirst}-${licensePlateSecond} ${licensePlateThird}`,
      });
  }

  register(
    token: string,
    registrationData: RegistrationRequestData | StellantisRegistrationRequestData,
  ): Observable<null> {
    return this.http.post<null>(`${environment.api.register}/${token.toLowerCase()}`, registrationData);
  }
}
