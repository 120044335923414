<div nxLayout="grid maxwidth">
  <app-card-header [showBackButton]="false"
                   [subTitle]="'injury.subTitle' | translate"
                   [title]="'injury.title' | translate">
  </app-card-header>
  <app-figure [source]="'injury.img' | translate" minHeight="142px">

  </app-figure>
  <app-card-buttons [emergencyEnabled]="true"
                    [emergency]="'injury.emergency' | translate"
                    [emergencyNumber]="'injury.emergencyNumber' | translate"
                    [showEmergencyIcon]="true"
                    [forwardRoute]="'injury.forwardRoute' | translate"
                    [forward]="'injury.forward' | translate"
                    [defaultNarrowWidthOnDesktop]="false">
  </app-card-buttons>
</div>
