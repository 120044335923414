import {Component, HostListener, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Pattern} from '../../models/enum/Pattern';
import {ActivatedRoute, Router} from '@angular/router';
import {SimpleDialogComponent} from '../Dialogs/simple-dialog/simple-dialog.component';
import {NxDialogService} from '@aposin/ng-aquila/modal';
import {ErrorType, ErrorCode} from '../../models/errorData';
import {ErrorService} from '../../services/error.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.sass']
})
export class ConfirmationComponent implements OnInit {
  formGroup: FormGroup;
  formBuilder: FormBuilder;
  token = '';

  constructor(private readonly route: ActivatedRoute,
              private readonly router: Router,
              public dialogService: NxDialogService,
              private readonly errorService: ErrorService) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.focusInputFieldAfterPageLoad();
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams.errorCode && queryParams.errorCode !== '200') {
        this.openErrorDialog(queryParams.errorCode);
        this.formGroup.get('phone').setValue(queryParams.mobileNumber);

        queryParams.doesOriginalInputIncludeUmlauts
          ? this.formGroup.get('licensePlateFirst').setValue(queryParams.unrevisedLicensePlateFirst)
          : this.formGroup.get('licensePlateFirst').setValue(queryParams.licensePlateFirst);

        this.formGroup.get('licensePlateSecond').setValue(queryParams.licensePlateSecond);

        queryParams.doesOriginalInputIncludeSpaces
          ? this.formGroup.get('licensePlateThird').setValue(queryParams.unrevisedLicensePlateThird)
          : this.formGroup.get('licensePlateThird').setValue(queryParams.licensePlateThird);
      }

      if (queryParams) {
        this.token = queryParams.token;
        if (!this.token) {
          this.openErrorDialog('404');

          this.errorService.reportError({
            type: ErrorType.ERROR,
            code: ErrorCode.TOKEN_MISSING,
            token: this.token,
            url: window.location.href,
          }).subscribe();
        }
      }
    });
  }

  private openErrorDialog(errorCode: string): void {
    this.dialogService.open(SimpleDialogComponent, {
      data: {
        body: this.getErrorCode(errorCode)
      }
    });
  }

  private buildForm(): void {
    this.formBuilder = new FormBuilder();
    this.formGroup = this.formBuilder.group({
      phone: [
        '',
        Validators.compose(
          [
            Validators.required,
            Validators.pattern(Pattern.telefonnummer)
          ]),
      ],
      licensePlateFirst: [
        '',
        [
          Validators.required,
          Validators.pattern('^[A-Za-z0-9_äÄöÖüÜ]*$')
        ]
      ],
      licensePlateSecond: [
        '',
        [
          Validators.required,
          Validators.pattern('^[A-Za-z0-9]*$')
        ]
      ],
      licensePlateThird: [
        '',
        [
          Validators.pattern('^[A-Za-z0-9_äÄöÖüÜ]*$')
        ]
      ]
    });
  }

  goToRegistration(): void {
    const licensePlateFirstOriginal = this.formGroup.get('licensePlateFirst').value.toUpperCase();
    const licensePlateSecondOriginal = this.formGroup.get('licensePlateSecond').value.toUpperCase();
    const licensePlateThirdOriginal = this.formGroup.get('licensePlateThird').value.toUpperCase();

    const revisedLicensePlateFirst = this.reviseLicensePlate(licensePlateFirstOriginal, 1);
    const revisedLicensePlateThird = this.reviseLicensePlate(licensePlateThirdOriginal, 3);

    const doesInputIncludeUmlauts = this.getDoesInputIncludeUmlauts(licensePlateFirstOriginal);
    const doesInputIncludeSpaces = this.getDoesInputIncludeSpaces(licensePlateThirdOriginal);

    const validationData = {
      mobileNumber: this.formGroup.get('phone').value,
      unrevisedLicensePlateFirst: licensePlateFirstOriginal,
      unrevisedLicensePlateThird: licensePlateThirdOriginal,
      licensePlateFirst: revisedLicensePlateFirst,
      licensePlateSecond: licensePlateSecondOriginal,
      licensePlateThird: revisedLicensePlateThird,
      doesOriginalInputIncludeUmlauts: doesInputIncludeUmlauts,
      doesOriginalInputIncludeSpaces: doesInputIncludeSpaces,
      token: this.token
    };
    this.router.navigate(['/registration'], {queryParams: validationData});
  }

  private getDoesInputIncludeSpaces(licensePlateThird: string): boolean {
    return licensePlateThird.includes(' ');
  }

  getDoesInputIncludeUmlauts(licensePlateFirst: string): boolean {
    return licensePlateFirst.includes('Ä') || licensePlateFirst.includes('Ö') || licensePlateFirst.includes('Ü');
  }

  reviseLicensePlate(licensePlate: string, order: number): string {
    if (order === 1) {
      return licensePlate
        // BÖ (Börde) is treated individually as there is a license plate clash with BO (Bochum)
        .replace(/BÖ/g, 'BOE')
        .replace(/Ä/g, 'A')
        .replace(/Ö/g, 'O')
        .replace(/Ü/g, 'U');
    } else if (order === 3) {
      if (licensePlate[licensePlate.length - 1] === 'E') {
        return licensePlate.replace('E', ' E');
      } else {
        return licensePlate;
      }
    }
  }

  isLicensePlateValid(): boolean {
    return (this.formGroup.get('licensePlateFirst').touched && this.formGroup.get('licensePlateFirst').invalid)
      || (this.formGroup.get('licensePlateSecond').touched && this.formGroup.get('licensePlateSecond').invalid)
      || (this.formGroup.get('licensePlateThird').touched && this.formGroup.get('licensePlateThird').invalid);
  }

  focusInputFieldAfterPageLoad(): void {
    setTimeout(() => document.getElementById('cy-contact-telefon').focus());
  }

  private getErrorCode(errorCode: string): string {
    switch (errorCode) {
      case '400':
        return 'simple-dialog.verification-body';
      case '404':
        return 'simple-dialog.no-token-body';
      default:
        return 'simple-dialog.error-body';
    }
  }

  @HostListener('window:beforeunload', [ '$event' ])
  beforeUnloadHandler(event): void {
    this.errorService.reportError({
      type: ErrorType.WARN,
      code: ErrorCode.CONFIRMATION,
      token: this.token,
      url: window.location.href,
    }).subscribe();
  }
}
