<div nxLayout="grid maxwidth">
  <div class="margin-bottom-24px-mobile-64px-desktop nx-margin-top-m" nxRow nxRowJustify="center">
    <div nxCol="12,12,8,8">
      <h4 class="nx-margin-bottom-m" nxHeadline="subsection-medium">{{'success.title' | translate}}</h4>
      <p class="u-text-align-center" nxCopytext="normal">
        <span [innerHTML]="'success.subsubTitle.one' | translate"></span>
        <span class="blue-color" [innerHTML]="'success.subsubTitle.two' | translate"></span>
        <span [innerHTML]="'success.subsubTitle.three' | translate"></span>
      </p>
    </div>
  </div>
  <div>
    <div class="snake container start">
      <div class="snake grid-m"></div>
      <div class="snake container grid-l content down">
        <div class="snake empty right hide-on-mobile"></div>
        <div class="snake empty left hide-on-desktop"></div>
        <div class="snake empty"></div>
      </div>
      <div class="snake grid-m"></div>
    </div>

    <div class="snake container">
      <div class="snake grid-m">
      </div>
      <div class="snake container grid-l">
        <div class="margin-left-3px">
          <div class="snake headline hide-on-desktop left-headline">
            {{'success.snake.one.number' | translate}}
          </div>
          <img class="notify-badge hide-on-desktop" src="/assets/icons_success/Hook.svg" alt="hook"/>
        </div>
        <div class="snake wheel top-left-invisible"></div>
        <div class="snake empty-desktop hide-on-mobile"></div>
        <div class="snake horizontal hide-on-desktop"></div>
        <div class="snake headline hide-on-mobile">
          {{'success.snake.one.number' | translate}}
        </div>
        <img class="notify-badge hide-on-mobile" src="/assets/icons_success/Hook.svg" alt="hook"/>
        <div class="snake horizontal"></div>
        <div class="snake wheel top-right"></div>
      </div>
      <div class="snake grid-m">
      </div>
    </div>

    <!-- Unfallmeldung abgeschickt -->
    <div class="snake container">
      <div class="snake grid-m"></div>
      <div class="snake grid-l content right">
        <div class="wastuncontainer textcontent-with-image-right">
          <h3 class="text-color nx-margin-y-s align-inherit"
              nxHeadline="subsection-large">{{'success.snake.one.title' | translate}}</h3>
          <div class="textcontent">
            <p nxCopytext="normal">{{'success.snake.one.subtitle' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="snake grid-m">
        <img class="image-n1" src="/assets/icons_success/N1.svg" alt="car-accident-report-sent"/>
      </div>
    </div>

    <div class="snake container">
      <div class="snake grid-m">
      </div>
      <div class="snake container grid-l">
        <div class="snake wheel top-left"></div>
        <div class="snake horizontal down"></div>
        <div class="snake headline hide-on-mobile down-headline">{{'success.snake.two.number' | translate}}</div>
        <div class="snake horizontal down"></div>
        <div class="snake wheel bottom-right">
          <div
            class="snake headline hide-on-desktop down-headline left-margin">{{'success.snake.two.number' | translate}}</div>
        </div>
      </div>
      <div class="snake grid-m"></div>
    </div>

    <!-- Wir melden uns  -->
    <div class="snake container">
      <div class="snake grid-m">
        <img class="image-n2" src="/assets/icons_success/N2.svg" alt="we-will-call-you"/>
      </div>
      <div class="snake grid-l content left">
        <div class="wastuncontainer textcontent-with-image-left">
          <h3 class="nx-margin-y-s align-inherit"
              nxHeadline="subsection-large">{{'success.snake.two.title' | translate}}</h3>
          <p nxCopytext="normal">{{'success.snake.two.subtitle' | translate}}</p>
        </div>
      </div>
      <div class="snake grid-m"></div>
    </div>
    <div class="snake container">
      <div class="snake grid-m"></div>
      <div class="snake container grid-l">
        <div class="snake wheel bottom-left">
          <div
            class="snake headline hide-on-desktop down-headline left-headline">{{'success.snake.three.number' | translate}}</div>
        </div>
        <div class="snake horizontal down"></div>
        <div class="snake headline hide-on-mobile down-headline">{{'success.snake.three.number' | translate}}</div>
        <div class="snake horizontal down"></div>
        <div class="snake wheel top-right down"></div>
        <div class="snake empty-wheel hide-on-desktop"></div>
      </div>
      <div class="snake grid-m"></div>
    </div>

    <!-- Infos des Unfallbeteiligten -->
    <div class="snake container">
      <div class="snake grid-m"></div>
      <div *ngIf="showContactOpponent()" class="snake grid-l content right">
        <div class="wastuncontainer textcontent-with-image-right">
          <h3 class="text-color nx-margin-y-s align-inherit"
              nxHeadline="subsection-large">{{'success.snake.three.title' | translate}}</h3>
          <div class="textcontent">
            <p nxCopytext="normal">{{'success.snake.three.subtitle' | translate}}</p>
          </div>
        </div>
      </div>
      <div *ngIf="!showContactOpponent()" class="snake grid-l content">
        <div class="wastuncontainer textcontent-with-image-right">
          <h3 class="text-color nx-margin-y-s align-inherit"
              nxHeadline="subsection-large">{{'success.snake.four.title' | translate}}</h3>
          <div class="textcontent">
            <p nxCopytext="normal">{{'success.snake.four.subtitle' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="snake grid-m">
        <img *ngIf="showContactOpponent()" class="image-n3" src="/assets/icons_success/N3.svg"
             alt="info-of-participants"/>
        <img *ngIf="!showContactOpponent()" class="image-n3" src="/assets/icons_success/N4.svg" alt="repaired"/>
      </div>
    </div>

    <div *ngIf="showContactOpponent()" class="snake container">
      <div class="snake grid-m">
        <img class="image-n4" src="/assets/icons_success/N4.svg" alt="repaired"/>
      </div>
      <div class="snake container grid-l">
        <div class="snake horizontal down"></div>
        <div class="snake headline hide-on-mobile down-headline">{{'success.snake.four.number' | translate}}</div>
        <div class="snake horizontal down"></div>
        <div class="snake wheel bottom-right">
          <div
            class="snake headline hide-on-desktop down-headline left-margin">{{'success.snake.four.number' | translate}}</div>
        </div>
      </div>
      <div class="snake grid-m"></div>
    </div>


    <!-- Alles erledigt -->
    <div *ngIf="showContactOpponent()" class="snake container">
      <div class="snake grid-m">
      </div>
      <div class="snake grid-l content">
        <div class="wastuncontainer textcontent-with-image-right nx-margin-bottom-4xl">
          <h3 class="nx-margin-y-s align-inherit"
              nxHeadline="subsection-large">{{'success.snake.four.title' | translate}}</h3>
          <p nxCopytext="normal">{{'success.snake.four.subtitle' | translate}}</p>
        </div>
      </div>
      <div class="snake container"></div>
      <div class="snake grid-m">
      </div>
    </div>
  </div>
</div>

<div class="feedback-app">
  <div class="snake container">
    <div class="snake grid-m"></div>
    <div class="snake grid-l content">
      <div class="textcontent-with-no-image">
        <h4 class="nx-margin-bottom-m" nxHeadline="subsection-small">{{'success.feedback.title' | translate}}</h4>
        <p nxCopytext="normal" [innerHTML]="'success.feedback.subtitle' | translate"></p>
        <a *ngFor="let number of [0,1,2,3,4]"
           target="_blank"
           (mouseover)="hover(number, 'star')"
           (mouseleave)="hover(number, 'star-o')"
           rel="noopener noreferrer"
           [href]="'success.feedback.link-vote' | translate:{ azid: providerData?.providerDetails?.impact_id, vote: number+1 }">
          <nx-icon [name]='starTypes[number]' size="s" class="gold"></nx-icon>
        </a>
      </div>
    </div>
    <div class="snake grid-m"></div>
  </div>
  <div class="snake container">
    <div class="snake grid-l content">
      <a nxButton="primary medium"
         target="_blank"
         rel="noopener noreferrer"
         [href]="'success.feedback.link' | translate:{ azid: providerData?.providerDetails?.impact_id}"
         class="nx-margin-top-2m">
        {{'success.feedback.submit' | translate}}
      </a>
    </div>
  </div>
</div>

<div class="promo-app background-blue">
  <div class="snake container">
    <div class="snake grid-m"></div>
    <div class="snake grid-l content">
      <div class="textcontent-with-no-image">
        <h4 class="nx-margin-bottom-m" nxHeadline="subsection-small">{{'success.footer.title' | translate}}</h4>
        <p nxCopytext="normal">{{'success.footer.subtitle' | translate}}</p>
      </div>
    </div>
    <div class="snake grid-m"></div>
  </div>
  <div class="snake container">
    <div class="snake grid-l content">
      <img class="phone-image" src="/assets/icons_success/ad.svg" alt="ad"/>
    </div>
  </div>
</div>

