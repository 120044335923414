import {AfterContentInit, Component, Input} from '@angular/core';

@Component({
  selector: 'app-figure',
  templateUrl: './figure.component.html',
  styleUrls: ['./figure.component.sass']
})
export class FigureComponent implements AfterContentInit {

  @Input()
  source: string;

  @Input()
  minHeight: string;
  loaded = false;

  ngAfterContentInit(): void {
    this.loaded = true;
  }
}

