import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HeaderComponent} from './components/header/header.component';
import {NdbxSharedModule} from './ndbx-shared.module';
import {FooterComponent} from './components/footer/footer.component';
import {CardButtonsComponent} from './components/card-buttons/card-buttons.component';
import {StartComponent} from './components/start/start.component';
import {FalsePositiveComponent} from './components/false-positive/false-positive.component';
import {TipComponent} from './components/tip/tip.component';
import {CardHeaderComponent} from './components/card-header/card-header.component';
import {FigureComponent} from './components/figure/figure.component';
import {InjuryComponent} from './components/injury/injury.component';
import {IncidentComponent} from './components/incident/incident.component';
import {GuiltComponent} from './components/guilt/guilt.component';
import {ContactComponent} from './components/contact/contact.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PhotoComponent} from './components/photo/photo.component';
import {ReadyComponent} from './components/ready/ready.component';
import {AnimalTipsComponent} from './components/tips-animal/tips-animal.component';
import {EditIconHeadlineComponent} from './components/edit-icon-headline/edit-icon-headline.component';
import {SummaryComponent} from './components/summary/summary.component';
import {SuccessComponent} from './components/success/success.component';
import {AdditionalInformationComponent} from './components/additional-information/additional-information.component';
import {InformationComponent} from './components/information/information.component';
import {CookieComponent} from './components/cookie/cookie.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AccidentPartiesInvolvedComponent} from './components/accident-parties-involved/accident-parties-involved.component';
import {NxSpinnerModule} from '@aposin/ng-aquila/spinner';
import {StoreModule} from '@ngrx/store';
import {MailReducer} from './reducers/mailservice/mail.reducer';
import {EffectsModule} from '@ngrx/effects';
import {MailEffects} from './reducers/mailservice/mail.effects';
import {ProviderDataReducer} from './reducers/providerdataservice/provider-data.reducer';
import {ProviderDataEffects} from './reducers/providerdataservice/provider-data.effects';
import {NotificationComponent} from './components/notification/notification.component';
import {SimpleNotificationsModule} from 'angular2-notifications';
import {ENV, getEnv} from '../environments/environment.provider';
import {ConfirmationComponent} from './components/confirmation/confirmation.component';
import {RegistrationComponent} from './components/registration/registration.component';
import {NxContextMenuModule} from '@aposin/ng-aquila/context-menu';
import { SimpleDialogComponent } from './components/Dialogs/simple-dialog/simple-dialog.component';
import {NxCheckboxModule} from '@aposin/ng-aquila/checkbox';
import { RegistrationSuccessComponent } from './components/registration-success/registration-success.component';
import {NxTooltipModule} from '@aposin/ng-aquila/tooltip';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

function appInitializerFactory(translate: TranslateService): () => Promise<any> {
  return () => {
    if (translate.getBrowserLang() === 'de') {
      translate.setDefaultLang('de');
      return translate.use('de').toPromise();
    } else {
      translate.setDefaultLang('en');
      return translate.use('en').toPromise();
    }
  };
}

@NgModule({
  declarations: [
    AdditionalInformationComponent,
    AppComponent,
    AnimalTipsComponent,
    CookieComponent,
    HeaderComponent,
    FooterComponent,
    CardButtonsComponent,
    ContactComponent,
    PhotoComponent,
    ReadyComponent,
    StartComponent,
    FalsePositiveComponent,
    TipComponent,
    CardHeaderComponent,
    FigureComponent,
    IncidentComponent,
    InformationComponent,
    InjuryComponent,
    GuiltComponent,
    EditIconHeadlineComponent,
    SummaryComponent,
    SuccessComponent,
    AccidentPartiesInvolvedComponent,
    NotificationComponent,
    ConfirmationComponent,
    RegistrationComponent,
    SimpleDialogComponent,
    RegistrationSuccessComponent
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        NdbxSharedModule,
        SimpleNotificationsModule,
        NxSpinnerModule,
        ReactiveFormsModule,
        SimpleNotificationsModule.forRoot(),
        StoreModule.forRoot({
            selectedMailState: MailReducer,
            selectedProviderDataState: ProviderDataReducer,
        }),
        TranslateModule.forRoot({
            defaultLanguage: 'de',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        EffectsModule.forRoot([
            MailEffects,
            ProviderDataEffects
        ]),
        FormsModule,
        NxContextMenuModule,
        NxCheckboxModule,
        NxTooltipModule,
    ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true
    },
    {provide: ENV, useFactory: getEnv}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
