export enum storageKey {
  contact = 'contact',
  trackingCookieAccepted = 'trackingCookieAccepted',
  marketingCookieAccepted = 'marketingCookieAccepted',
}

export enum storageValue {
  accepted = 'accepted',
  notAccepted = 'not-accepted'
}
