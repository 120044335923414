import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ContactStoreService} from '../../services/store/contact-store.service';
import {UAXPerson} from '../../models/ua-x-person';
import {Pattern} from '../../models/enum/Pattern';
import {ActivatedRoute, Router} from '@angular/router';
import {AtlTrackingService} from '../../atl/atl-tracking.service';
import {TrackingPoints} from '../../atl/tracking-points';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.sass']
})
export class ContactComponent implements OnInit {

  @Input()
  source: string;
  formGroup: FormGroup;
  formBuilder: FormBuilder;
  selectedContact: UAXPerson;
  contacts: UAXPerson[];
  contactID = 0;
  storeNewContact: boolean;

  cityCode = '';
  letterCode = '';
  numberCode = '';
  private readonly contactIDQueryParam = 'contactID';

  constructor(private readonly contactStoreService: ContactStoreService,
              private readonly route: ActivatedRoute,
              private readonly router: Router,
              private readonly atlTrackingService: AtlTrackingService) {
  }

  ngOnInit(): void {
    this.atlTrackingService.applyIfRouteValid(TrackingPoints.contact);
    this.chooseContact();

    if (!!this.selectedContact.licenseplate) {
      this.parseLicensePlate(this.selectedContact.licenseplate);
    }

    this.formBuilder = new FormBuilder();
    this.formGroup = this.formBuilder.group({
      salutation: [this.selectedContact.salutation, Validators.required],
      firstname: [this.selectedContact.firstname, Validators.required],
      lastname: [this.selectedContact.lastname, Validators.required],
      companyname: [this.selectedContact.companyname],
      companydriver: [this.selectedContact.companydriver],
      email: [
        this.selectedContact.email,
        Validators.compose([
          Validators.required,
          Validators.pattern(Pattern.email)
        ]),
      ],
      phone: [
        this.selectedContact.phone,
        Validators.compose([
          Validators.required,
          Validators.pattern(Pattern.telefonnummer),
        ]),
      ],
      city: [
        this.cityCode,
        [Validators.required,
          Validators.pattern('^[A-Za-z0-9_äÄöÖüÜ]*$')
        ]
      ],
      letters: [
        this.letterCode,
        [Validators.required,
          Validators.pattern('^[A-Za-z0-9_äÄöÖüÜ]*$')
        ]
      ],
      numbers: [
        this.numberCode,
        [Validators.required,
          Validators.pattern('^[0-9]*$')
        ]
      ]
    });
    this.salutationChanege();
  }

  salutationChanege(): void {
    this.selectedContact.companySelected = this.formGroup.get('salutation').value === 'Firma';
    if (this.selectedContact.companySelected) {
      this.updateValidators(['companyname', 'companydriver'], ['firstname', 'lastname']);
    } else {
      this.updateValidators(['firstname', 'lastname'], ['companyname', 'companydriver']);
    }
    ['companyname', 'companydriver', 'firstname', 'lastname'].forEach(formfield =>
      this.formGroup.get(formfield).updateValueAndValidity());
  }

  calcLicensePlate(): void {
    this.cityCode = this.formGroup.get('city').value.replace(/[^A-Za-zäÄöÖüÜ]/g, '');
    this.formGroup.get('city').setValue(this.cityCode);
    this.letterCode = this.formGroup.get('letters').value.replace(/[^A-Za-zäÄöÖüÜ]/g, '');
    this.formGroup.get('letters').setValue(this.letterCode);
    this.numberCode = this.formGroup.get('numbers').value.replace(/[^0-9]/g, '');
    this.formGroup.get('numbers').setValue(this.numberCode);

    this.selectedContact.licenseplate = `${this.cityCode}-${this.letterCode} ${this.numberCode}`;
  }

  onSubmit(): void {
    if (this.storeNewContact) {
      this.contacts.push(this.selectedContact);
      this.contactID = this.contacts.length - 1;
    }
    this.selectedContact.firstname = this.formGroup.get('firstname').value;
    this.selectedContact.lastname = this.formGroup.get('lastname').value;
    this.selectedContact.salutation = this.formGroup.get('salutation').value;
    this.selectedContact.email = this.formGroup.get('email').value;
    this.selectedContact.phone = this.formGroup.get('phone').value;
    const cityConcatLetter = `${this.cityCode}-${this.letterCode}`;
    this.selectedContact.licenseplate = `${cityConcatLetter.toUpperCase()} ${this.numberCode}`;
    this.selectedContact.companydriver = this.formGroup.get('companydriver').value;
    this.selectedContact.companyname = this.formGroup.get('companyname').value;
    this.selectedContact.companySelected = this.formGroup.get('salutation').value === 'Firma';
    this.contacts[this.contactID] = this.selectedContact;
    this.contactStoreService.set(this.contacts);
    this.router.navigate(['/contact-list']);
  }

  parseLicensePlate(initValue: string): void {
    let value = initValue.split('-', 2);
    this.cityCode = value[0];
    value = value[1].split(' ', 2);
    this.letterCode = value[0];
    this.numberCode = value[1];
  }

  isLicensePlateValid(): boolean {
    return (this.formGroup.get('city').touched && this.formGroup.get('city').invalid) ||
      (this.formGroup.get('letters').touched && this.formGroup.get('letters').invalid) ||
      (this.formGroup.get('numbers').touched && this.formGroup.get('numbers').invalid);
  }

  private updateValidators(requiredValidators: string[], notRequiredValidators: string[]): void {
    requiredValidators.forEach(reqiredValidator => this.formGroup.get(reqiredValidator).setValidators(Validators.required));
    notRequiredValidators.forEach(notReqiredValidator => {
      this.formGroup.get(notReqiredValidator).clearValidators();
      this.formGroup.get(notReqiredValidator).setValue(undefined);
    });
  }

  private chooseContact(): void {
    this.contacts = this.contactStoreService.get();
    if (this.contacts.length === 0 || this.route.snapshot.queryParamMap.get(this.contactIDQueryParam) === 'add') {
      this.storeNewContact = true;
      this.selectedContact = {} as UAXPerson;
    } else {
      const contactIDFromRoute = parseFloat(this.route.snapshot.queryParamMap.get(this.contactIDQueryParam));
      this.contactID = isNaN(contactIDFromRoute) ? this.contacts.length - 1 : contactIDFromRoute;
      this.selectedContact = this.contacts[this.contactID];
    }
  }
}
