import {Component, OnInit} from '@angular/core';
import {AtlTrackingService} from '../../atl/atl-tracking.service';
import {TrackingPoints} from '../../atl/tracking-points';

@Component({
  selector: 'app-injury',
  templateUrl: './injury.component.html',
  styleUrls: ['./injury.component.sass']
})
export class InjuryComponent implements OnInit {
  constructor(private readonly atlTrackingService: AtlTrackingService) {
  }

  ngOnInit(): void {
    this.atlTrackingService.applyIfRouteValid(TrackingPoints.injury);
  }
}
