<div nxLayout="grid maxwidth">
  <app-card-header [showBackButton]="true"
                   [subTitle]="'animal.subTitle' | translate"
                   [title]="'animal.title' | translate">
  </app-card-header>
  <app-figure [source]="'animal.img' | translate" minHeight="183px">
  </app-figure>
  <app-card-buttons [forwardRoute]="'animal.forwardRoute' | translate"
                    [forward]="'animal.forward' | translate"
                    [emergencyEnabled]="true"
                    [emergency]="'animal.emergency' | translate"
                    [emergencyNumber]="'animal.emergencyNumber' | translate"
                    [defaultNarrowWidthOnDesktop]="false">
  </app-card-buttons>
</div>
