import { Component, OnInit } from '@angular/core';
import {AtlTrackingService} from '../../atl/atl-tracking.service';
import {TrackingPoints} from '../../atl/tracking-points';

@Component({
  selector: 'app-tip',
  templateUrl: './tip.component.html',
  styleUrls: ['./tip.component.sass']
})
export class TipComponent implements OnInit {

  constructor(private readonly atlTrackingService: AtlTrackingService) {
  }

  ngOnInit(): void {
    if (this.atlTrackingService.isStartEventSent()) {
      this.atlTrackingService.applyIfRouteValid(TrackingPoints.tip);
    } else {
      this.atlTrackingService.sendStartBusinessEvent();
    }
  }
}
