import * as imapActoins from './mail.actions';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export interface MailState {
  isLoading: boolean;
}

const initialState: MailState = {
  isLoading: false
};

export function MailReducer(state = initialState, action: imapActoins.Actions): MailState {
  switch (action.type) {
    case imapActoins.SEND_MAIL:
      return {isLoading: true} as MailState;
    case imapActoins.MAIL_SEND_SUCCESS:
      return {isLoading: false} as MailState;
    case imapActoins.MAIL_SEND_FAIL:
      return {isLoading: false} as MailState;
    default:
      return state;
  }
}

export const selectedMailState = createFeatureSelector('selectedMailState');
export const selectMailState = createSelector(selectedMailState, (state: MailState) => state.isLoading);
