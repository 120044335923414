import {Component, OnInit} from '@angular/core';
import {UAXPerson} from '../../models/ua-x-person';
import {UploadedFile} from '../../models/uploadedFile';
import {FileStoreService} from '../../services/store/file-store.service';
import {ContactStoreService} from '../../services/store/contact-store.service';
import {ReadyToDriveService} from '../../services/store/ready-to-drive.service';
import {IncidentTypeStoreService} from '../../services/store/incident-type-store.service';
import {IncidentType} from '../../models/incidentType';
import {InformationService} from '../../services/store/information.service';
import {FinalizeUAAcceleratorData} from '../../models/finalize-ua-accelerator-data';
import {ProviderData} from '../../models/providerData';
import {ProviderDataStoreService} from '../../services/store/provider-data-store.service';
import {Store} from '@ngrx/store';
import {SendMail} from '../../reducers/mailservice/mail.actions';
import {AtlTrackingService} from '../../atl/atl-tracking.service';
import {TrackingPoints} from '../../atl/tracking-points';
import {RerouteToSummaryService} from '../../services/store/reroute-to-summary.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.sass'],
})
export class SummaryComponent implements OnInit {
  contacts: UAXPerson[];
  imageList: Array<UploadedFile> = [];
  isReadyToDrive: boolean;
  incidentType: IncidentType;
  information: string;
  providerData: ProviderData;

  constructor(private readonly fileStoreService: FileStoreService,
              private readonly contactStoreService: ContactStoreService,
              private readonly readyToDriveService: ReadyToDriveService,
              private readonly incidentStoreService: IncidentTypeStoreService,
              private readonly providerDataStoreService: ProviderDataStoreService,
              private readonly informationService: InformationService,
              private readonly store: Store<any>,
              private readonly atlTrackingService: AtlTrackingService,
              private readonly rerouteToSummaryService: RerouteToSummaryService) {
  }

  ngOnInit(): void {
    this.atlTrackingService.applyIfRouteValid(TrackingPoints.summary);
    this.imageList = this.fileStoreService.get();
    this.isReadyToDrive = this.readyToDriveService.get();
    this.incidentType = this.incidentStoreService.get();
    this.contacts = this.contactStoreService.get();
    this.information = this.informationService.get();
    this.providerData = this.providerDataStoreService.get();
    this.rerouteToSummaryService.set();
  }

  showContact(): boolean {
    return this.incidentType === IncidentType.CAR;
  }

  getTitle(headline: string, index: number): string {
    if (this.contacts.length > 1) {
      return headline + ' ' + (index + 1);
    }
    return headline;
  }

  onSubmit(): void {
    const finalizeUAAcceleratorData = {
      dateOfAccident: this.providerData?.dateOfAccident,
      information: this.information,
      insured: this.providerData?.insured,
      opponent: this.contacts,
      location: this.providerData?.location,
      providerDetails: this.providerData?.providerDetails,
      readyToDrive: this.isReadyToDrive,
      incidentType: this.incidentType
    } as FinalizeUAAcceleratorData;

    this.store.dispatch(new SendMail(finalizeUAAcceleratorData, this.imageList, '/success'));
  }
}
