<app-cookie id="cy-cookie"></app-cookie>
<div class="ua-x-background">
  <div nxLayout="grid maxwidth" *ngIf="(providerData$ | async) as providerData">
    <div *ngIf="showPersonalizedData(providerData?.providerDetails?.provider)">
      <div nxRow nxRowJustify="center">
        <div nxCol="12,12,8,8">
          <p nxCopytext="normal" class="nx-margin-y-xs u-text-align-center blue-color nx-font-weight-semibold"
             [innerHTML]="'start.greeting.personalized' | translate">
          </p>
          <h2 class="nx-font-weight-bold nx-margin-0" nxHeadline="subsection-medium"
              [innerHTML]="providerData?.insured?.salutation + ' ' + providerData?.insured?.lastname">
          </h2>
        </div>
      </div>
      <div nxRow nxRowJustify="center">
        <div nxCol="12">
          <p *ngIf="!providerData?.location?.adress" nxCopytext="normal" class="nx-margin-y-s blue-color u-text-align-center nx-font-weight-regular"
             [innerHTML]="('start.message.personalized.first' | translate) + providerData?.dateOfAccident +
                          ('start.message.personalized.third' | translate)">
          </p>
          <p *ngIf="providerData?.location?.adress" nxCopytext="normal" class="nx-margin-y-s blue-color u-text-align-center nx-font-weight-regular"
             [innerHTML]="('start.message.personalized.first' | translate) + providerData?.dateOfAccident +
                          ('start.message.personalized.second' | translate) + providerData?.location?.adress +
                          ('start.message.personalized.third' | translate)">
          </p>
        </div>
      </div>
    </div>
    <div *ngIf="!showPersonalizedData(providerData?.providerDetails?.provider)">
      <div nxRow nxRowJustify="center">
        <div nxCol="12,12,8,8">
          <h2 class="nx-font-weight-bold nx-margin-top-xs nx-margin-bottom-0" nxHeadline="subsection-medium"
              [innerHTML]="'start.greeting.unpersonalized.title' | translate">
          </h2>
          <p nxCopytext="normal" class="u-text-align-center blue-color nx-font-weight-semibold"
             [innerHTML]="'start.greeting.unpersonalized.subtitle' | translate">
          </p>
        </div>
      </div>
      <div nxRow nxRowJustify="center">
        <div nxCol="12">
          <p *ngIf="!providerData?.location?.adress" nxCopytext="normal" class="nx-margin-y-s blue-color u-text-align-center nx-font-weight-regular"
             [innerHTML]="('start.message.unpersonalized.first' | translate) + providerData?.dateOfAccident +
                          ('start.message.unpersonalized.third' | translate)">
          </p>
          <p *ngIf="providerData?.location?.adress" nxCopytext="normal" class="nx-margin-y-s blue-color u-text-align-center nx-font-weight-regular"
             [innerHTML]="('start.message.unpersonalized.first' | translate) + providerData?.dateOfAccident +
                          ('start.message.unpersonalized.second' | translate) + providerData?.location?.adress +
                          ('start.message.unpersonalized.third' | translate)">
          </p>
        </div>
      </div>
    </div>
    <app-card-buttons [jumpEnabled]="true"
                      [jumpLinkWithIconOnDesktop]="true"
                      [jump]="'start.jump' | translate"
                      [jumpRoute]="'start.jumpRoute' | translate"
                      [forwardRoute]="'start.forwardRoute' | translate"
                      [forward]="'start.forward' | translate">
    </app-card-buttons>
  </div>
</div>
