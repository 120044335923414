import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  public window: Window;

  constructor() {
    this.window = window;
  }

  getWindow(): Window {
    return this.window;
  }
}
