<div nxLayout="grid maxwidth">
  <app-card-header [showBackButton]="true"
                   [title]="'additional-information.title' | translate"
                   [subTitle]="'additional-information.subtitle' | translate">
  </app-card-header>

  <div nxLayout="grid nogutters" class="nav-step--bottom">
    <div nxRow nxRowJustify="start,start,center" nxRowAlignItems="start">
      <div nxCol="12,12,6,8" nxAlignSelf="start" class="buttons">
        <button nxButton="secondary"
                type="button"
                [routerLink]="'additional-information.insuredRoute' | translate"
                class="call-help u-padding-sm"
                id="cy-additional-information-jump">
          {{'additional-information.insured' | translate}}
        </button>
        <button nxButton="primary"
                type="button"
                class="u-padding-sm center"
                [routerLink]="'additional-information.forwardRoute' | translate"
                id="cy-additional-information-forward">
          {{'additional-information.forward' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
