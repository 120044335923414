import {Injectable} from '@angular/core';
import {AbstractTrackEvent, PageViewTrackEvent, StartTrackEvent, StepTrackEvent, SuccessTrackEvent} from './track-event';
import {BusinessConfig} from './business-config';
import {TrackingPoints} from './tracking-points';
import {ITrackingService} from './tracking-service';
import {TrackingServiceWindow} from './tracking-service-window';
import {AtlTrackingKey, EventName} from './event-name';
import {StorageService} from '../services/storage.service';
import {IncidentType} from '../models/incidentType';
import {INCIDENT_TYPE, sonstigeSchaeden, unfallMitKfz, unfallMitTier} from '../components/incident/incident.component';
import {IncidentTypeStoreService} from '../services/store/incident-type-store.service';

declare const window: TrackingServiceWindow;

@Injectable({
  providedIn: 'root'
})
export class AtlTrackingService {

  IS_START_EVENT_SENT = 'IS_START_EVENT_SENT';
  IS_SUCCESS_EVENT_SENT = 'IS_SUCCESS_EVENT_SENT';

  trackingPointsMap: Map<string, AbstractTrackEvent>;
  trackingService: ITrackingService;
  businessConfig = new BusinessConfig();

  constructor(private readonly incidentTypeStoreService: IncidentTypeStoreService,
              private readonly storageService: StorageService) {
    this.initTrackingPoints();
  }

  initTrackingServiceForPageView(): ITrackingService {
    this.businessConfig.resetProcessVars();
    return this.initTrackingServiceFor();
  }

  initTrackingServiceForStep(): ITrackingService {
    this.businessConfig.setProcessVars();
    return this.initTrackingServiceFor();
  }

  initTrackingServiceFor(): ITrackingService {
    if (window.om && window.om.azde && window.om.azde.AllianzTrackingLibrary) {
      this.trackingService = window.om.azde.AllianzTrackingLibrary.createTrackingService(this.businessConfig);
      return this.trackingService;
    }
    return null;
  }

  initTrackingPoints(): void {
    this.trackingPointsMap = new Map<string, AbstractTrackEvent>();

    this.trackingPointsMap.set(TrackingPoints.startBusinessEventTip,
      new StartTrackEvent(TrackingPoints.tipStepName, TrackingPoints.tipStepNumber));
    this.trackingPointsMap.set(TrackingPoints.successBusinessEventSuccess,
      new SuccessTrackEvent(TrackingPoints.successStepName, TrackingPoints.successStepNumber));

    this.trackingPointsMap.set(TrackingPoints.start,
      new PageViewTrackEvent(TrackingPoints.startPageName));
    this.trackingPointsMap.set(TrackingPoints.falsePositive,
      new PageViewTrackEvent(TrackingPoints.falsePositivePageName));

    this.trackingPointsMap.set(TrackingPoints.tip,
      new StepTrackEvent(TrackingPoints.tipStepName, TrackingPoints.tipStepNumber));
    this.trackingPointsMap.set(TrackingPoints.injury,
      new StepTrackEvent(TrackingPoints.injuryStepName, TrackingPoints.injuryStepNumber));
    this.trackingPointsMap.set(TrackingPoints.incident,
      new StepTrackEvent(TrackingPoints.incidentStepName, TrackingPoints.incidentStepNumber));
    this.trackingPointsMap.set(TrackingPoints.guilt,
      new StepTrackEvent(TrackingPoints.guiltStepName, TrackingPoints.guiltStepNumber));
    this.trackingPointsMap.set(TrackingPoints.tipAnimal,
      new StepTrackEvent(TrackingPoints.tipAnimalStepName, TrackingPoints.tipAnimalStepNumber));

    this.trackingPointsMap.set(TrackingPoints.additionalInformation,
      new StepTrackEvent(TrackingPoints.additionalInformationStepName, TrackingPoints.additionalInformationStepNumber));
    this.trackingPointsMap.set(TrackingPoints.information,
      new StepTrackEvent(TrackingPoints.informationStepName, TrackingPoints.informationStepNumber));
    this.trackingPointsMap.set(TrackingPoints.contact,
      new StepTrackEvent(TrackingPoints.contactStepName, TrackingPoints.contactStepNumber));
    this.trackingPointsMap.set(TrackingPoints.contactList,
      new StepTrackEvent(TrackingPoints.contactListStepName, TrackingPoints.contactListStepNumber));

    this.trackingPointsMap.set(TrackingPoints.photo,
      new StepTrackEvent(TrackingPoints.photoStepName, TrackingPoints.photoStepNumber));
    this.trackingPointsMap.set(TrackingPoints.photoUploaded,
     new StepTrackEvent(TrackingPoints.photoUploadedStepName, TrackingPoints.photoUploadedStepNumber));

    this.trackingPointsMap.set(TrackingPoints.summary,
      new StepTrackEvent(TrackingPoints.summaryStepName, TrackingPoints.summaryStepNumber));
    this.trackingPointsMap.set(TrackingPoints.ready,
      new StepTrackEvent(TrackingPoints.readyStepName, TrackingPoints.readyStepNumber));

    this.trackingPointsMap.set(TrackingPoints.success,
      new StepTrackEvent(TrackingPoints.successStepName, TrackingPoints.successStepNumber));
  }

  apply(trackEvent: AbstractTrackEvent): void {
    this.applyValue(trackEvent);
    this.applyTrack(trackEvent);
  }


  applyIfRouteValid(route: string): void {
    const abstractTrackEvent: AbstractTrackEvent = this.trackingPointsMap.get(route);
    if (abstractTrackEvent) {
      this.applyValue(abstractTrackEvent);
      this.applyTrack(abstractTrackEvent);
    }
  }

  applyValue(trackEvent: AbstractTrackEvent): void {
    if (this.trackingService && trackEvent) {
      if (trackEvent instanceof PageViewTrackEvent) {
        this.trackingService.setValue(
          AtlTrackingKey.page_pageInfo_pageName,
          this.businessConfig.get_page_pageInfo_pageName(trackEvent.pageName)
        );
      } else {
        if (!this.businessConfig.processLocation) {
          this.initTrackingServiceForStep();
        }
        this.setAttributeForIncident(this.incidentTypeStoreService.get());
        this.setValueFor(AtlTrackingKey.process_stepName, trackEvent.stepName);
        this.setValueFor(AtlTrackingKey.process_stepNumber, trackEvent.stepNumber);
      }
    }
  }

  setValueFor(key: string, value: string): void {
    if (key === AtlTrackingKey.process_stepNumber && !value.endsWith('.')) {
      this.throwsError(`${AtlTrackingKey.process_stepNumber} should be ending with a full stop!`);
    }
    this.trackingService.setValue(key, value);
  }

  throwsError(errorMessage: string): never {
    throw new Error(errorMessage);
  }

  applyTrack(trackEvent: AbstractTrackEvent): void {
    if (this.trackingService) {
      this.trackingService.track(trackEvent);
      if (trackEvent.name === EventName.start) {
        this.storageService.set(this.IS_START_EVENT_SENT, true);
      } else if (trackEvent.name === EventName.success) {
        this.storageService.set(this.IS_SUCCESS_EVENT_SENT, true);
      }
    }
  }

  shouldSendStartOrSuccessBusinessEvent(route: string): void {
    if (this.onTipAndStartEventNotSent(route) ||
      this.onSuccessPagesAndSuccessEventNotSent(route)
    ) {
      this.applyIfRouteValid(route);
    }
  }

  private onSuccessPagesAndSuccessEventNotSent(route: string): boolean {
    return (route === TrackingPoints.successBusinessEventSuccess)
      && !this.isSuccessEventSent();
  }

  private onTipAndStartEventNotSent(route: string): boolean {
    return route === TrackingPoints.startBusinessEventTip && !this.isStartEventSent();
  }

  isStartEventSent(): boolean {
    return this.storageService.get(this.IS_START_EVENT_SENT);
  }

  isSuccessEventSent(): boolean {
    return this.storageService.get(this.IS_SUCCESS_EVENT_SENT);
  }

  sendStartBusinessEvent(): void {
    this.shouldSendStartOrSuccessBusinessEvent(TrackingPoints.startBusinessEventTip);
  }

  deleteSuccessEventInStorage(): void {
    this.storageService.delete(this.IS_SUCCESS_EVENT_SENT);
  }

  setAttribute(name: string, value: string): void {
    const quoteAttribute = 'quote.attribute';
    try {
      this.trackingService.addValue(quoteAttribute, {name, value});
    } catch {}
  }

  setAttributeForIncident(incidentType: IncidentType): void {
    if (incidentType === IncidentType.CAR) {
      this.setAttribute(INCIDENT_TYPE, unfallMitKfz);
    } else if (incidentType === IncidentType.ANIMAL) {
      this.setAttribute(INCIDENT_TYPE, unfallMitTier);
    } else if (incidentType === IncidentType.OTHER) {
      this.setAttribute(INCIDENT_TYPE, sonstigeSchaeden);
    }
  }
}
