import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InformationService {

  private readonly information = 'information';

  constructor() {
  }

  get(): string {
    return !!localStorage.getItem(this.information) ?
      localStorage.getItem(this.information) :
      '';
  }

  set(information: string): void {
    localStorage.setItem(this.information, information);
  }
}
