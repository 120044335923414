<div class="ua-x-background">
  <div nxLayout="grid maxwidth">
    <div nxRow nxRowJustify="center">
      <div nxCol="12,12,8,8">
        <h2 class="nx-font-weight-bold margin-top-12" nxHeadline="subsection-medium"
            [innerHTML]="'false-positive.thanks' | translate">
        </h2>
        <p nxCopytext="normal" class="nx-margin-0 u-text-align-center blue-color nx-font-weight-semibold"
           [innerHTML]="'false-positive.false' | translate">
        </p>
      </div>
    </div>
    <div nxRow nxRowJustify="center">
      <div nxCol="12">
        <p nxCopytext="normal" class="nx-margin-y-s blue-color u-text-align-center nx-font-weight-regular"
           [innerHTML]="'false-positive.message' | translate">
        </p>
      </div>
    </div>
    <div nxRow nxRowJustify="center" class="display-on-desktop">
      <div nxCol="12">
        <h4 class="nx-margin-y-xs" nxHeadline="subsection-small">
          <nx-link>
            <a onclick="window.location='tel:080011223344'">
              <nx-icon name="phone" class="nx-margin-right-s" size="auto"></nx-icon>
              {{'false-positive.number' | translate}}
            </a>
          </nx-link>
        </h4>
      </div>
    </div>
  </div>
  <div class="sticky-footer">
    <div nxRow nxRowJustify="center">
      <div [nxCol]="'12,12,6,4'">
        <div class="display-on-mobile">
          <button class="responsive-padding-with-icon responsive-margins white-background-on-desktop"
                  nxButton="secondary medium"
                  onclick="window.location='tel:08000000021'"
                  type="button">
            <nx-icon class="nx-margin-0 change-icon-color"
                     name="phone"
                     size="s">
            </nx-icon>
            {{'false-positive.contact' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
