<div nxLayout="grid maxwidth">
  <app-card-header [showBackButton]="false"
                   [subTitle]="'tip.subTitle' | translate"
                   [title]="'tip.title' | translate">
  </app-card-header>
  <app-figure [source]="'tip.img' | translate" minHeight="155.99px">
  </app-figure>
  <app-card-buttons [forwardRoute]="'tip.forwardRoute' | translate"
                    [forward]="'tip.forward' | translate">
  </app-card-buttons>
</div>
