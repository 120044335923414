<div class="nx-margin-bottom-3xl">
  <div nxLayout="grid maxwidth">
    <app-card-header [showBackButton]="true"
                     [subTitle]="'photo.subTitle.' + hasFiles() | translate"
                     [title]="'photo.title.' + hasFiles() | translate">
    </app-card-header>
    <div *ngIf="!hasFiles()" class="margin-bottom-24px-mobile-64px-desktop" nxRow nxRowJustify="center">
      <div nxCol="12,12,8,8">
        <p class="u-text-align-center nx-margin-0 nx-font-weight-bold" nxCopytext="normal">
          {{'photo.tip1' | translate}}
        </p>
        <p class="u-text-align-center nx-margin-0" nxCopytext="normal">
          {{'photo.tip2' | translate}}
        </p>
      </div>
    </div>

    <div *ngIf="hasFiles()" nxRow nxRowJustify="start,center" class="nx-margin-top-4m">
      <nx-error *ngIf="fileSizeExceeded()">
        {{'photo.error' | translate}}
      </nx-error>
      <div *ngFor="let image of images; let index = index">
        <div class="nx-margin-x-s">
          <figure nxFigure>
            <div (click)="removeFile(index)" class="remove-image">
              <nx-icon name='close-circle' size="s"></nx-icon>
            </div>
            <div class="image-frame cy-preview" *ngIf="image.isImage"
                 [style.background-image]="'url(' + image.src + ')'"
            ></div>
            <nx-icon class="image-frame cy-preview" *ngIf="!image.isImage" name='pdf' size="l"></nx-icon>
          </figure>
        </div>
      </div>
    </div>

    <div class="nx-margin-top-4m" nxRow nxRowJustify="start,center" nxRowAlignItems="center">
      <div nxCol="12,12,6,4" class="uploadImage">
        <input #file (change)="onFilesAdded($event)" accept="image/*,.pdf" multiple type="file"/>
        <nx-link>
          <a (click)="addFiles()">
            <nx-icon name="product-plus" size="l"></nx-icon>
          </a>
        </nx-link>
        <p nxCopytext="small">{{'photo.accepted' | translate}}</p>
      </div>
    </div>


    <app-card-buttons [forwardRoute]="getRoute('photo.summary' | translate,'photo.forwardRoute' | translate)"
                      [forward]="'photo.forward' | translate"
                      [disabled]="fileSizeExceeded()"
    >
    </app-card-buttons>
  </div>
</div>
