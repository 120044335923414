import {Component, OnDestroy, OnInit} from '@angular/core';
import {ReadyToDriveService} from '../../services/store/ready-to-drive.service';
import {AtlTrackingService} from '../../atl/atl-tracking.service';
import {TrackingPoints} from '../../atl/tracking-points';

export const FAHRBEREITSCHAFT_TYPE = 'Fahrbereitschaft';
export const fahrbereit = 'fahrbereit';
export const nichtFahrbereit = 'nicht_fahrbereit';

@Component({
  selector: 'app-ready',
  templateUrl: './ready.component.html',
  styleUrls: ['./ready.component.sass'],
})
export class ReadyComponent implements OnInit, OnDestroy {

  buttonClicked = false;
  constructor(private readonly readyToDriveService: ReadyToDriveService,
              private readonly atlTrackingService: AtlTrackingService) {
  }

  ngOnInit(): void {
    this.atlTrackingService.applyIfRouteValid(TrackingPoints.ready);
  }

  setReadyToDrive(option: boolean): void {
    if (option) {
      this.readyToDriveService.set(true);
    } else {
      this.readyToDriveService.set(false);
    }
    this.buttonClicked = true;
  }

  ngOnDestroy(): void {
    if (this.buttonClicked) {
      this.readyToDriveService.get()
      ? this.atlTrackingService.setAttribute(FAHRBEREITSCHAFT_TYPE, fahrbereit)
      : this.atlTrackingService.setAttribute(FAHRBEREITSCHAFT_TYPE, nichtFahrbereit);
    }
  }
}
