import {Component, TemplateRef, ViewChild} from '@angular/core';
import {NotificationService} from './notification.service';
import {NotificationsService} from 'angular2-notifications';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.sass']
})
export class NotificationComponent {

  @ViewChild('notificationTemplate')
  notificationTemplate: TemplateRef<any>;

  public notificationsOptions = {
    timeOut: 10000,
    showProgressBar: true
  };

  public context: string;
  public title: string;
  public message: string;

  constructor(private readonly appNotification: NotificationService,
              private readonly notification: NotificationsService ) {
    this.appNotification.event.subscribe(val => {
      this.context = val.context;
      this.title = val.title;
      this.message = val.message;
      this.notification.bare(this.notificationTemplate);
    });
  }
}
