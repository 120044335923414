import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ProviderData} from '../../models/providerData';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProviderDataService {

  constructor(private readonly http: HttpClient) {
  }

  fetchProviderData(customerID: string): Observable<ProviderData> {
    return this.http.get<ProviderData>(environment.api.providerData, {params: {customerID}});
  }
}
