import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UploadedFile} from '../../models/uploadedFile';
import {FileStoreService} from '../../services/store/file-store.service';
import {ImageCompressionService} from '../../services/image-compression.service';
import {Options} from '../../models/compression-options';
import {AtlTrackingService} from '../../atl/atl-tracking.service';
import {TrackingPoints} from '../../atl/tracking-points';
import {RerouteToSummaryService} from '../../services/store/reroute-to-summary.service';

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.sass']
})
export class PhotoComponent implements OnInit, OnDestroy {

  @ViewChild('file') file;
  images: Array<UploadedFile> = [];
  options: Options = {
    maxSizeMB: 3,
    maxWidthOrHeight: 1024,
    useWebWorker: false
  };
  twentyMB = 20 * 1024 * 1014;

  constructor(private readonly fileStoreService: FileStoreService,
              private readonly imageCompressionService: ImageCompressionService,
              private readonly atlTrackingService: AtlTrackingService,
              private readonly rerouteToSummaryService: RerouteToSummaryService) {
  }

  ngOnInit(): void {
    this.atlTrackingService.applyIfRouteValid(TrackingPoints.photo);
    this.images = this.fileStoreService.get();
  }

  ngOnDestroy(): void {
    this.fileStoreService.set(this.images);
  }

  hasFiles(): boolean {
    return this.images.length > 0;
  }

  onFilesAdded(fileInput: ProgressEvent<HTMLInputElement>): void {
    this.atlEventChange(TrackingPoints.photoUploaded);

    const newFiles: FileList = fileInput.target.files;
    Array.from(newFiles).forEach(newFile => {
      if (this.canCompress(newFile)) {
        this.compressFile(newFile);
      } else {
        this.storeFile(newFile);
      }
    });
    fileInput.target.value = null;
  }

  canCompress(file: File): boolean {
    if (file.type) {
      return !!file.type.match(/image/);
    }
    return false;
  }

  isImage(fileName: string): boolean {
    return !(fileName?.endsWith('.pdf'));
  }

  addFiles(): void {
    this.file.nativeElement.click();
  }

  removeFile(index: number): void {
    this.images.splice(index, 1);
    this.atlEventChange(TrackingPoints.photo);
  }

  atlEventChange(route: string): void {
    if (!this.hasFiles()) {
      this.atlTrackingService.applyIfRouteValid(route);
    }
  }

  getRoute(summaryRoute: string, forwardRoute: string): string {
    return this.rerouteToSummaryService.get() ? summaryRoute : forwardRoute;
  }

  private compressFile(file: File): void {
    this.imageCompressionService.compress(file, this.options).subscribe((resultFile: any) => {
      if (resultFile) {
        this.storeFile(resultFile);
      }
    });
  }

  private storeFile(newFile: File): void {
    const reader = new FileReader();
    reader.readAsDataURL(newFile);
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const fileReaderResult: string | ArrayBuffer = event.target.result;

      this.images.push({
        fileName: newFile.name,
        src: fileReaderResult,
        isImage: this.isImage(newFile.name),
        size: newFile.size
      } as UploadedFile);
    };
  }

  fileSizeExceeded(): boolean {
    return this.images.reduce((sum, file) => sum + file.size, 0) > this.twentyMB;
  }
}
