import { Component, OnInit } from '@angular/core';
import {AtlTrackingService} from '../../atl/atl-tracking.service';
import {TrackingPoints} from '../../atl/tracking-points';

@Component({
  selector: 'app-guilt',
  templateUrl: './guilt.component.html',
  styleUrls: ['./guilt.component.sass']
})
export class GuiltComponent implements OnInit {

  constructor(private readonly atlTrackingService: AtlTrackingService) {
  }

  ngOnInit(): void {
    this.atlTrackingService.applyIfRouteValid(TrackingPoints.guilt);
  }
}
