<div class="sticky-footer">
  <div nxRow nxRowJustify="center">
    <div [nxCol]="'12,12,' + getWidthOnDesktop()">
      <div *ngIf="jumpEnabled && jumpLinkWithIconOnDesktop"
           class="show-on-desktop justify-content-center nx-margin-bottom-s">
        <nx-link nxStyle='block'
                 class="nx-margin-top-m">
          <a [routerLink]="jumpRoute">
            <nx-icon name='arrow-right'></nx-icon>
            <span class="text-transform-uppercase">
              {{jump}}
            </span>
          </a>
        </nx-link>
      </div>
      <div class="display-flex-on-desktop">
        <button *ngIf="emergencyEnabled"
                class="responsive-padding-with-icon responsive-margins white-background-on-desktop"
                nxButton="secondary medium"
                (click)="callEmergencyNumber()" type="button">
          <nx-icon *ngIf="showEmergencyIcon"
                   class="nx-margin-x-xs change-icon-color"
                   fill="true"
                   name="product-health-bag-doctor"
                   outline="true"
                   size="s">
          </nx-icon>
          {{emergency}}
        </button>
        <button *ngIf="jumpEnabled && !jumpLinkWithIconOnDesktop"
                [routerLink]='jumpRoute'
                class="responsive-margins blue-background-on-desktop show-on-desktop"
                id="cy-card-button-jump"
                (click)="jumpOnClick()"
                nxButton="secondary medium"
                type="button">
          {{jump}}
        </button>

        <button *ngIf="jumpEnabled"
                [routerLink]='jumpRoute'
                class="responsive-margins hide-on-desktop"
                id="cy-card-button-jump-mobile"
                (click)="jumpOnClick()"
                nxButton="secondary medium"
                type="button">
          {{jump}}
        </button>

        <button [routerLink]='forwardRoute'
                [disabled]="disabled"
                nxButton="primary medium"
                (click)="forwardOnClick()"
                type="submit"
                id="cy-card-button-forward"
                class="nx-margin-0">
          {{forward}}
        </button>
      </div>
    </div>
  </div>
</div>
