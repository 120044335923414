<div nxLayout="grid maxwidth">
  <div class="margin-bottom-24px-mobile-64px-desktop nx-margin-top-m" nxRow nxRowJustify="center">
    <div nxCol="12,12,8,8">
      <h4 class="nx-margin-bottom-m" nxHeadline="subsection-medium">{{'registration-success.title' | translate}}</h4>
      <div *ngIf="!withTokenPrefix">
        <p class="u-text-align-center" nxCopytext="normal">
          <span [innerHTML]="'registration-success.subTitle.one' | translate"></span> <br><br>
          <span [innerHTML]="'registration-success.subTitle.two' | translate"></span>
        </p>
        <div class="sticky-footer">
          <div nxRow nxRowJustify="center">
            <div class="display-flex-on-desktop">
              <a [href]="'registration-success.guide-url' | translate"
                 target="_blank">
                <button nxButton="primary medium"
                        type="button"
                        class="nx-margin-0">
                  <span [innerHTML]="'registration-success.go-to-guide-text' | translate"></span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="withTokenPrefix">
      <p class="u-text-align-center" nxCopytext="normal">
        <span [innerHTML]="'registration-success.subTitleWithTokenPrefix' | translate"></span> <br><br>
      </p>
      </div>
    </div>
  </div>
</div>
