import {Component, Inject, OnInit} from '@angular/core';
import {NX_MODAL_DATA, NxModalRef} from '@aposin/ng-aquila/modal';

@Component({
  selector: 'app-simple-dialog',
  templateUrl: './simple-dialog.component.html',
  styleUrls: ['./simple-dialog.component.sass']
})
export class SimpleDialogComponent implements OnInit {
  body: string;

  constructor(public dialogRef: NxModalRef<SimpleDialogComponent>,
              @Inject(NX_MODAL_DATA) public data: {body: string}) {
    this.body = data.body;
  }

  ngOnInit(): void {
    console.log(this.body);
  }

}
