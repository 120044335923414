<div class="dialog">
  <h2>{{'simple-dialog.title' | translate}}</h2>
  <div>
    <span nxCopytext="large" class="text">{{body | translate}}</span> <br><br>
    <span *ngIf="body === 'simple-dialog.verification-body'" nxCopytext="large" class="text">{{'simple-dialog.verification-body-contact' | translate}}</span> <br>
    <span *ngIf="body === 'simple-dialog.verification-body'" nxCopytext="large" class="text">{{'simple-dialog.verification-body-tel' | translate}}</span> <br>
    <span *ngIf="body === 'simple-dialog.verification-body'" nxCopytext="large" class="text">{{'simple-dialog.verification-body-email' | translate}}</span> <br>
  </div>

  <div style="display: flex; justify-content: center;">
    <button nxButton="primary small" style="width: 100px; margin-top: 40px" (click)="dialogRef.close()">{{'simple-dialog.buttontext' | translate}}</button>
  </div>
</div>
