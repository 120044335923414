/* tslint:disable */
import {Injectable} from '@angular/core';
import {ConsumerConfig, TrackingServiceWindow} from './tracking-service-window';
import {environment} from '../../environments/environment';

declare const window: TrackingServiceWindow;

// copied and rewritten to typescript: https://neremor.homelinux.org/azde/atl-demowebsite/js/atl-config.js
@Injectable({
  providedIn: 'root'
})
export class AtlConfigService {

  constructor() {
    this.initConfig();
  }

  initConfig(): void {
    this.mapGlobalLibraryToSpecificNamespace();

    this.initLaunchConfig();

    this.initTealiumConfig();

    this.initDtmConfigObjects();
    this.createTealiumConfig();
  }

  initDtmConfigObjects(): void {
    const _DTM = [];
    const _DTM_CONF = [];
    /* */
    _DTM['lastClickIndex'] = 0;
    _DTM['visitorNamespace'] = 'onemarketingazd';
    _DTM['trackingServer'] = 'sdc.allianz.de';
    _DTM['companyPrefix'] = 'azd';
    _DTM['defaultOrgEntity'] = 'dev';

    _DTM_CONF['campaignParams'] = 'azmedid;cid;cmp_id;campaign_id;campaign_from_facebook';
    _DTM_CONF['campaignFragmentKey'] = 'azmedid';

    _DTM['AA_TimeZone'] = [];
    _DTM['AA_TimeZone']['hemisphere'] = 'n';
    _DTM['AA_TimeZone']['timeZoneOffset'] = 1;
    _DTM['AA_TimeZone']['continent'] = 'europe';

    _DTM['DST'] = [];
    _DTM['DST']['europe'] = {
      2017: '3/26,10/29',
      2018: '3/25,10/28',
      2019: '3/31,10/27',
      2020: '3/29,10/25',
      2021: '3/28,10/31'
    };
    _DTM['DST']['us'] = {
      2017: '3/12,11/5',
      2018: '3/11,11/4',
      2019: '3/10,11/3',
      2020: '3/8,11/1',
      2021: '3/14,11/7'
    };
    _DTM['DST']['australia'] = {
      2017: '4/2,10/1',
      2018: '4/1,10/7',
      2019: '4/7,10/6',
      2020: '4/5,10/4',
      2021: '4/4,10/3'
    };

    _DTM['AAM'] = [];
    _DTM['AAM']['partner'] = 'azd';
    _DTM['AAM']['containerNSID'] = 0;
    _DTM['AAM']['uuidCookieName'] = 'aam_uuid';
    _DTM['AAM']['uuidCookieExp'] = 30;
    /* */
    window._DTM = _DTM;
    window._DTM_CONF = _DTM_CONF;
  }

  getCookie(name): string {
    const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
    return v ? v[2] : null;
  }

  setCookie(name, value, days): void {
    if (days === 0) {
      document.cookie = `${name}=${value}`;
    } else {
      const d = new Date();
      d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
      document.cookie = `${name}=${value};path=/;expires=${d.toUTCString()}`;
    }
  }

  handleSatTrackCookie(): void {
    if (this.getCookie('sat_track') === null) {
      this.setCookie('sat_track', 'true', 0);
    }
  }

  createTealiumConfig(): void {
    window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
    window.utag_cfg_ovrd.noview = true;
    window.utag_data = {};
  }

  mapGlobalLibraryToSpecificNamespace(): void {
    if (!window.om) {
      window.om = {};
    }
    if (!window.om.azde) {
      window.om.azde = {};
    }

    if (!window.om.azde.AllianzTrackingLibrary) {
      window.om.azde.AllianzTrackingLibrary = window.AllianzTrackingLibrary;
    } else {
      const list = window.om.azde.AllianzTrackingLibrary.getDefaultConsumerConfig();
      while (list.length > 0) {
        list.pop();
      }
    }
  }

  private initLaunchConfig(): void {
    const launchNameAtlGenericEvents = 'ATL_Generic_Events';

    const launchConsumerConfig: ConsumerConfig = {
      name: 'oneMarketing-Launch-Consumer',
      type: 'LaunchConsumerType',
      config: {
        eventNameToLaunchMapping: [
          {
            eventName: 'pageview',
            launchName: launchNameAtlGenericEvents
          },
          {
            eventName: 'start',
            launchName: launchNameAtlGenericEvents
          },
          {
            eventName: 'leavesite',
            launchName: launchNameAtlGenericEvents
          },
          {
            eventName: 'firstprice',
            launchName: launchNameAtlGenericEvents
          },
          {
            eventName: 'quote',
            launchName: launchNameAtlGenericEvents
          },
          {
            eventName: 'success',
            launchName: launchNameAtlGenericEvents
          },
          {
            eventName: 'step',
            launchName: launchNameAtlGenericEvents
          },
          {
            eventName: 'download',
            launchName: launchNameAtlGenericEvents
          },
          {
            eventName: 'interaction',
            launchName: launchNameAtlGenericEvents
          },
          {
            eventName: 'open',
            launchName: launchNameAtlGenericEvents
          },
          {
            eventName: 'usage',
            launchName: launchNameAtlGenericEvents
          },
          {
            eventName: 'close',
            launchName: launchNameAtlGenericEvents
          },
          {
            eventName: 'techinfo',
            launchName: launchNameAtlGenericEvents
          }
        ],
        envToUrlMapping: {
          default: environment.stage,
          dev: 'https://assets.adobedtm.com/97527f1ab2da/c58c2e709c09/launch-ef7f8842949b-development.min.js',
          test: 'https://assets.adobedtm.com/97527f1ab2da/c58c2e709c09/launch-ef7f8842949b-development.min.js',
          prod: 'https://www.allianz.de/tgm3/prod/97527f1ab2da/c210c3150bf2/launch-d3d1af0c00b2.min.js'
        }
      }
    };
    if (window.om && window.om.azde && window.om.azde.AllianzTrackingLibrary) {
      window.om.azde.AllianzTrackingLibrary.addDefaultConsumerConfig(launchConsumerConfig);
    }
  }

  private initTealiumConfig(): void {
    const tealiumConsumerConfig = {
      name: 'oneMarketing-Tealium-consumer',
      type: 'TealiumConsumerType',
      config: {
        envToUrlMapping: {
          default: environment.stage,
          dev: 'https://www.allianz.de/tgm1/allianz-deutschland/int-allianzde-prod/dev/utag.js',
          test: 'https://www.allianz.de/tgm1/allianz-deutschland/int-allianzde-prod/stage/utag.js',
          prod: 'https://www.allianz.de/tgm1/allianz-deutschland/int-allianzde-prod/prod/utag.js'
        }
      }
    };
    if (window.om && window.om.azde && window.om.azde.AllianzTrackingLibrary) {
      window.om.azde.AllianzTrackingLibrary.addDefaultConsumerConfig(tealiumConsumerConfig);
    }
  }

  createDefaultEnvironment(performance?, targeting?, stage?, explicit?): void {
    window.manualATLEnvironment = true;
    if (performance === undefined) {
      performance = true;
    }
    if (targeting === undefined) {
      targeting = false;
    }
    if (stage === undefined) {
      stage = 'dev';
    }
    if (explicit === undefined) {
      explicit = (performance !== undefined && targeting !== undefined);
    }

    if (!explicit && window.OptanonActiveGroups) {
      performance = false;
      targeting = false;
      if (window.OptanonActiveGroups.indexOf(',2,') >= 0) {
        performance = true;
      }
      if (window.OptanonActiveGroups.indexOf(',4,') >= 0) {
        targeting = true;
      }
    }
    if (window.om && window.om.azde && window.om.azde.AllianzTrackingLibrary) {
      window.om.azde.AllianzTrackingLibrary.setDefaultEnvironment({
        name: 'AllianzDE-Ext',
        // tslint:disable-next-line
        stage: stage,
        defaultSettings: {
          page: {
            attributes: {
              organisationalEntity: 'azd'
            }
          },
          user: {
            consent: {
              any: performance || targeting,
              // tslint:disable-next-line
              performance: performance,
              // tslint:disable-next-line
              targeting: targeting
            }
          }
        }
      });
    }
  }
}
