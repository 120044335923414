import {Injectable} from '@angular/core';
import {UploadedFile} from '../../models/uploadedFile';

@Injectable({
  providedIn: 'root',
})
export class FileStoreService {
  private readonly files = 'files';
  pdfs: Array<UploadedFile> = [];

  get(): Array<UploadedFile> {
    const cache = localStorage.getItem(this.files);
    if (!!cache) {
      let parsed = JSON.parse(cache);
      if (this.pdfs.length) {
        parsed = [...this.pdfs, ...parsed];
      }
      return parsed;
    } else {
      return [];
    }
  }

  set(files: Array<UploadedFile>): void {
    this.pdfs = this.getPdfs(files);
    files = this.getImages(files);
    localStorage.setItem(this.files, JSON.stringify(files));
  }

  private getPdfs(files: UploadedFile[]): UploadedFile[] {
    return files.filter(uploadedFile => !uploadedFile.isImage);
  }

  private getImages(files: UploadedFile[]): UploadedFile[] {
    return files.filter(uploadedFile => uploadedFile.isImage);
  }
}
