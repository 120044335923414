import {Component, Input, OnInit} from '@angular/core';
import {WindowService} from '../../services/window.service';

@Component({
  selector: 'app-card-header',
  templateUrl: './card-header.component.html',
  styleUrls: ['./card-header.component.sass']
})
export class CardHeaderComponent implements OnInit {

  @Input()
  title: string;
  @Input()
  subTitle: string;
  @Input()
  showBackButton: boolean;

  constructor(private readonly windowService: WindowService) {
  }

  ngOnInit(): void{
  }

  goBack(): void {
    this.windowService.getWindow().history.back();
  }

}
