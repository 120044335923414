export class TrackingPoints {
  public static readonly startBusinessEventTip = 'start business event on tip page';
  public static readonly successBusinessEventSuccess = 'success business event on success page';

  public static readonly confirmation = 'confirmation';
  public static readonly confirmationPageName = 'bestätigung';

  public static readonly registration = 'registration';
  public static readonly registrationPageName = 'registrierung';

  public static readonly registrationSuccess = 'registrationSuccess';
  public static readonly registrationSuccessPageName = 'registrierung_erfolg';

  public static readonly start = 'start';
  public static readonly startPageName = 'startseite';

  public static readonly falsePositive = 'false-positive';
  public static readonly falsePositivePageName = 'fehlermeldung';

  public static readonly tip = 'tip';
  public static readonly tipStepNumber = '01.';
  public static readonly tipStepName = 'sicherheit_am_unfallort';

  public static readonly injury = 'injury';
  public static readonly injuryStepNumber = '02.';
  public static readonly injuryStepName = 'erste_hilfe_tipp';

  public static readonly incident = 'incident';
  public static readonly incidentStepNumber = '03.';
  public static readonly incidentStepName = 'auswahl_der_unfallart';

  public static readonly guilt = 'guilt';
  public static readonly guiltStepNumber = '04.01.';
  public static readonly guiltStepName = 'unfall_kfz_tipp_schuldzugestaendnis';
  // TODO: add attribute for pages after incident in flow

  public static readonly contact = 'contact';
  public static readonly contactStepNumber = '04.02.';
  public static readonly contactStepName = 'unfall_kfz_kontaktdaten_des_unfallgegners';

  public static readonly contactList = 'contact-list';
  public static readonly contactListStepNumber = '04.02.01.';
  public static readonly contactListStepName = 'unfall_kfz_uebersicht_unfallbeteiligte';

  public static readonly photo = 'photo';
  public static readonly photoStepNumber = '07.01.';
  public static readonly photoStepName = 'unfallfotos_uebermitteln';

  public static readonly photoUploaded = 'photoUploaded';
  public static readonly photoUploadedStepNumber = '07.01.01.';
  public static readonly photoUploadedStepName = 'unfallfotos_ueberpruefen';

  public static readonly additionalInformation = 'additional-information';
  public static readonly additionalInformationStepNumber = '07.02.';
  public static readonly additionalInformationStepName = 'zusaetzliche_informationen_option';

  public static readonly information = 'information';
  public static readonly informationStepNumber = '07.02.01.';
  public static readonly informationStepName = 'zusaetzliche_informationen_eingabe';

  public static readonly ready = 'ready';
  public static readonly readyStepNumber = '07.03.';
  public static readonly readyStepName = 'fahrbereitschaft';

  public static readonly summary = 'summary';
  public static readonly summaryStepNumber = '07.04.';
  public static readonly summaryStepName = 'zusammenfassung';

  public static readonly success = 'success';
  public static readonly successStepNumber = '08.';
  public static readonly successStepName = 'fertig';

  public static readonly tipAnimal = 'tip-animal';
  public static readonly tipAnimalStepNumber = '05.01.';
  public static readonly tipAnimalStepName = 'unfall_mit_tier_verhaltenstipps';
}
