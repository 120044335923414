import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Path} from '../../../../ui-test/cypress/util/path';
import {ProviderDataStoreService} from '../../services/store/provider-data-store.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  languages = [{
    name: 'Deutsch',
    id: 'de'
  }, {
    name: 'English',
    id: 'en'
  }];

  selectedLanguage: {
    name: string;
    id: string;
  } = this.languages[0];

  constructor(
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly providerDataStoreService: ProviderDataStoreService) {
  }

  ngOnInit(): void {
    this.selectedLanguage = this.languages.find(l => l.id === this.translateService.currentLang);
  }

  showCallIconOnMobile(): boolean {
    return Path.INCIDENT === this.router.url &&
      ['CMT', 'MDGO'].includes(this.providerDataStoreService.get().providerDetails.provider.toUpperCase());
  }

  setLang(id: string, name: string): void {
    this.selectedLanguage = {
      name,
      id,
    };
    this.translateService.setDefaultLang(id);
    this.translateService.use(id);
  }
}
