export enum Path {
  START = '/start',
  TIP = '/tip',
  INJURY = '/injury',
  INCIDENT = '/incident',
  GUILT = '/guilt',
  ACCIDENT = '/accident',
  CONTACT = '/contact',
  CONTACTLIST = '/contact-list',
  PHOTO = '/photo',
  SUMMARY = '/summary',
  ADDITIONAL_INFORMATION = '/additional-information',
  INFORMATION = '/information',
  READY = '/ready',
  SUCCESS = '/success',
  TIPSANIMAL= '/tip-animal',
}
