import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {UploadedFile} from '../../models/uploadedFile';
import {FinalizeUAAcceleratorData} from '../../models/finalize-ua-accelerator-data';

@Injectable({
  providedIn: 'root',
})
export class MailService {
  constructor(private readonly httpClient: HttpClient) {
  }

  sendMail(
    body: FinalizeUAAcceleratorData,
    attachments: UploadedFile[]
  ): Observable<string> {
    const formData: FormData = new FormData();
    formData.append('body', JSON.stringify(body));
    if (!!attachments) {
      Array.from(attachments).forEach(
        (attachment: UploadedFile, idx: number) => {
          const img: File = this.dataURLtoFile(
            attachment.src,
            attachment.fileName
          );
          formData.append(`attachment_${idx}`, img, attachment.fileName);
        },
      );
    }

    return this.httpClient.post(environment.api.action, formData, {reportProgress: true, responseType: 'text'});
  }

  dataURLtoFile(dataurl: string, filename: string): File {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
  }
}
