import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {isLoading} from './selector/loading-selector';
import {ProviderDataStoreService} from './services/store/provider-data-store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  title = 'ua-x-integrierter-unfallmelder-frontend';
  public isLoading$: Observable<boolean>;

  constructor(private readonly route: ActivatedRoute,
              private readonly store: Store<any>,
              private readonly providerDataStore: ProviderDataStoreService) {
    this.isLoading$ = this.store.pipe(select(isLoading));
  }

  ngOnInit(): void {
  }
}
