<simple-notifications [options]="notificationsOptions"></simple-notifications>

<ng-template #notificationTemplate>
  <nx-message nxContext="{{context}}">
    <div nxLayout="grid nogutters">
      <div nxRow nxRowAlignItems="center">
        <div nxCol="4">
          <nx-icon *ngIf="context === 'error'" name='exclamation-triangle' size='m' class="triangle"></nx-icon>
          <nx-icon *ngIf="context === 'warning'" name='exclamation-circle' size='m' class="warning"></nx-icon>
          <nx-icon *ngIf="context === 'success'" name='check-circle' size='m' class="circle"></nx-icon>
        </div>
        <div nxCol="8">
          <p class="head">
            {{title}}
          </p>
        </div>
      </div>
      <div nxRow>
          <p class="center-text">
            {{message}}
          </p>
      </div>
    </div>
  </nx-message>
</ng-template>
