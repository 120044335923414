import {Action} from '@ngrx/store';
import {FinalizeUAAcceleratorData} from '../../models/finalize-ua-accelerator-data';
import {UploadedFile} from '../../models/uploadedFile';

export const SEND_MAIL = '[MAIL] SEND FROM STORE';
export const MAIL_SEND_SUCCESS = '[MAIL] SEND SUCCESS';
export const MAIL_SEND_FAIL = '[MAIL] SEND FAIL';

export class SendMail implements Action {
  readonly type = SEND_MAIL;
  constructor(public body: FinalizeUAAcceleratorData,
              public attachments: UploadedFile[],
              public targetRoute: string) {
  }
}

export class MailSendSuccess implements Action {
  readonly type = MAIL_SEND_SUCCESS;

  constructor() {
  }
}

export class MailSendFail implements Action {
  readonly type = MAIL_SEND_FAIL;

  constructor(public error: string) {
  }
}

export type Actions =
  | SendMail
  | MailSendFail
  | MailSendSuccess;
