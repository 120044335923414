import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-edit-icon-headline',
  templateUrl: './edit-icon-headline.component.html',
  styleUrls: ['./edit-icon-headline.component.sass']
})
export class EditIconHeadlineComponent implements OnInit {

  @Input()
  headLine: string;
  @Input()
  route: string;

  @Input()
  queryParam: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
