<div class="nx-margin-bottom-3xl">
  <div nxLayout="grid maxwidth">
    <app-card-header [showBackButton]="true"
                     [subTitle]="'contact.subTitle' | translate"
                     [title]="'contact.title' | translate">
    </app-card-header>

    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <div nxRow nxRowJustify="start,start,center" nxRowAlignItems="start">
        <div nxCol="12,12,8,6">
          <nx-formfield nxLabel="{{'contact.form.salutation' | translate}}">
            <nx-dropdown (selectionChange)="salutationChanege()"
                         formControlName="salutation"
                         id="cy-contact-salutation">
              <nx-dropdown-item [nxValue]="'contact.form.mrs' | translate">
                {{'contact.form.mrs' | translate}}
              </nx-dropdown-item>
              <nx-dropdown-item [nxValue]="'contact.form.mr' | translate">
                {{'contact.form.mr' | translate}}
              </nx-dropdown-item>
              <nx-dropdown-item [nxValue]="'contact.form.company' | translate">
                {{'contact.form.company' | translate}}
              </nx-dropdown-item>
            </nx-dropdown>
            <nx-error nxFormfieldError>
              {{'contact.form.validation' | translate}}
            </nx-error>
          </nx-formfield>
        </div>
      </div>
      <div *ngIf="!this.selectedContact.companySelected"
           nxRow
           nxRowJustify="start,start,center"
           nxRowAlignItems="start">
        <div nxCol="12,12,8,6">
          <nx-formfield nxLabel="{{'contact.form.firstname' | translate}}">
            <input nxInput
                   formControlName="firstname"
                   id="cy-contact-first-name"/>
            <nx-error nxFormfieldError>
              {{'contact.form.validation' | translate}}
            </nx-error>
          </nx-formfield>
        </div>
      </div>
      <div *ngIf="!this.selectedContact.companySelected"
           nxRow
           nxRowJustify="start,start,center"
           nxRowAlignItems="start">
        <div nxCol="12,12,8,6">
          <nx-formfield nxLabel="{{'contact.form.lastname' | translate}}">
            <input nxInput formControlName="lastname"
                   id="cy-contact-last-name"/>
            <nx-error nxFormfieldError>
              {{'contact.form.validation' | translate}}
            </nx-error>
          </nx-formfield>
        </div>
      </div>
      <div *ngIf="this.selectedContact.companySelected"
           nxRow
           nxRowJustify="start,start,center"
           nxRowAlignItems="start">
        <div nxCol="12,12,8,6">
          <nx-formfield nxLabel="{{'contact.form.companyname' | translate}}">
            <input nxInput
                   formControlName="companyname"
                   id="cy-contact-company-name"/>
            <nx-error nxFormfieldError>
              {{'contact.form.validation' | translate}}
            </nx-error>
          </nx-formfield>
        </div>
      </div>
      <div *ngIf="this.selectedContact.companySelected"
           nxRow
           nxRowJustify="start,start,center"
           nxRowAlignItems="start">
        <div nxCol="12,12,8,6">
          <nx-formfield nxLabel="{{'contact.form.companydriver' | translate}}">
            <input nxInput
                   formControlName="companydriver"
                   id="cy-contact-company-driver"/>
            <nx-error nxFormfieldError>
              {{'contact.form.validation' | translate}}
            </nx-error>
          </nx-formfield>
        </div>
      </div>
      <div nxRow
           nxRowJustify="start,start,center"
           nxRowAlignItems="start">
        <div nxCol="12,12,8,6">
          <nx-formfield nxLabel="{{'contact.form.phone' | translate}}">
            <input type="tel"
                   nxInput
                   formControlName="phone"
                   id="cy-contact-telefon"/>
            <nx-error nxFormfieldError>
              {{'contact.form.validation' | translate}}
            </nx-error>
          </nx-formfield>
        </div>
      </div>
      <div nxRow
           nxRowJustify="start,start,center"
           nxRowAlignItems="start">
        <div nxCol="12,12,8,6">
          <nx-formfield nxLabel="{{'contact.form.email' | translate}}">
            <input type="email"
                   nxInput
                   formControlName="email"
                   id="cy-contact-email"/>
            <nx-error nxFormfieldError>
              {{'contact.form.validation' | translate}}
            </nx-error>
          </nx-formfield>
        </div>
      </div>
      <div nxRow
           nxRowJustify="start,start,center"
           nxRowAlignItems="start">
        <div nxCol="12,12,8,6">
          <div nxRow class="align-col" nxRowJustify="start">
            <p class="u-text-align-left">{{'contact.form.licenseplate' | translate}}</p>
          </div>
            <div nxRow nxRowJustify="start" class="nx-margin-bottom-s align-col">
              <div class="outer-license-plate">
                <div class="license-plate-wrapper">
                  <img class="eu-symbol" alt="eu-license-plate"
                       src="/assets/licenseplate/eu_zeichen.svg">
                  <input type="text"
                         class="city border"
                         [maxLength]="3"
                         formControlName="city"
                         (keyup)="calcLicensePlate()"
                         id="cy-license-plate-city">
                  <img class="badge" alt="license-plate"
                       src="/assets/licenseplate/license_plate.svg">
                  <input type="text"
                         class="letters border"
                         [maxLength]="2"
                         formControlName="letters"
                         (keyup)="calcLicensePlate()"
                         id="cy-license-plate-letters">
                  <input type="text"
                         class="numbers border"
                         [maxLength]="4"
                         formControlName="numbers"
                         (keyup)="calcLicensePlate()"
                         id="cy-license-plate-numbers">
                </div>
              </div>
            </div>
            <div nxRow nxRowJustify="start" class="align-col">
              <nx-error nxFormfieldError *ngIf="isLicensePlateValid()">
                {{'contact.form.validation' | translate}}
              </nx-error>
            </div>
        </div>
      </div>
      <app-card-buttons [forward]="'contact.forward' | translate"
                        [disabled]='formGroup.invalid'>
      </app-card-buttons>
    </form>
  </div>
</div>
