<div class="nx-margin-bottom-3xl">
  <div nxLayout="grid maxwidth">
    <app-card-header [title]="'incident.title' | translate"
                     [showBackButton]="false">
    </app-card-header>

    <div nxRow >
      <div *ngFor="let type of 'incident.types' | translate" [nxCol]="'12,12,4,4'"
           [id]="'cy-stay-mobile-' + type.incidentType">

        <nx-selectable-card #nxSelectableCardComponents class="responsive-margin"
                            (selectionChange)="selectionChange($event, type)" [id]="type.incidentType">
          <nx-link nxStyle='block'>
            <a>
              <nx-icon [name]="type.icon" outline="true" size="auto"></nx-icon>
              <span
                class="font-size-20px margin-top-16px-non-mobile color-black text-align-center-on-non-mobile make-card-bottom-on-1-level">
                {{type.name}}
              </span>
            </a>
          </nx-link>
        </nx-selectable-card>

      </div>
    </div>

    <app-card-buttons
      [forward]="'incident.forward' | translate"
      [forwardRoute]="selectedOption.route"
      [disabled]="!selectedOption.route">
    </app-card-buttons>

  </div>
</div>
