<div class="nx-margin-top-m" nxRow nxRowJustify="center">
  <div nxCol="1">
    <div *ngIf="showBackButton">
      <nx-link size="large">
        <a (click)="goBack()">
          <nx-icon name="arrow-left"></nx-icon>
        </a>
      </nx-link>
    </div>
  </div>
  <div nxCol="10">
    <h4 class="nx-margin-bottom-m" nxHeadline="subsection-medium">{{title}}
    </h4>
  </div>
  <div nxCol="1"></div>
</div>
<div class="margin-bottom-24px-mobile-64px-desktop" nxRow nxRowJustify="center">
  <div nxCol="12,12,8,8">
    <p class="u-text-align-center nx-margin-0" nxCopytext="normal" [innerHTML]="subTitle">
    </p>
  </div>
</div>
