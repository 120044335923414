import {EventName} from './event-name';

export interface ITrackEvent {
  readonly name: EventName;
}

export class AbstractTrackEvent implements ITrackEvent {
  readonly name: EventName;
  readonly stepName: string;
  readonly stepNumber: string;
  readonly pageName?: string;

  constructor(name: EventName, stepName: string, stepNumber: string, pageName?: string) {
    this.name = name;
    this.stepName = stepName;
    this.stepNumber = stepNumber;
    this.pageName = pageName;
  }
}

export class StartTrackEvent extends AbstractTrackEvent {
  constructor(stepName: string, stepNumber: string) {
    super(EventName.start, stepName, stepNumber);
  }
}

export class SuccessTrackEvent extends AbstractTrackEvent {
  constructor(stepName: string, stepNumber: string) {
    super(EventName.success, stepName, stepNumber);
  }
}

export class StepTrackEvent extends AbstractTrackEvent {
  constructor(stepName: string, stepNumber: string) {
    super(EventName.step, stepName, stepNumber);
  }
}

export class PageViewTrackEvent extends AbstractTrackEvent {
  constructor(pageName: string) {
    super(EventName.pageview, '', '', pageName);
  }
}

