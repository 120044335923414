import {Component, OnInit} from '@angular/core';
import {IncidentTypeStoreService} from '../../services/store/incident-type-store.service';
import {IncidentType} from '../../models/incidentType';
import {AtlTrackingService} from '../../atl/atl-tracking.service';
import {TrackingPoints} from '../../atl/tracking-points';
import {ProviderData} from '../../models/providerData';
import {ProviderDataStoreService} from '../../services/store/provider-data-store.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.sass'],
})
export class SuccessComponent implements OnInit {
  incidentType: IncidentType;
  providerData: ProviderData;
  starTypes = ['star-o', 'star-o', 'star-o', 'star-o', 'star-o'];

  constructor(private readonly incidentStoreService: IncidentTypeStoreService,
              private readonly providerDataStoreService: ProviderDataStoreService,
              private readonly atlTrackingService: AtlTrackingService) {
    this.providerData = this.providerDataStoreService.get();
  }

  ngOnInit(): void {
    if (this.atlTrackingService.isSuccessEventSent()) {
      this.atlTrackingService.applyIfRouteValid(TrackingPoints.success);
    } else {
      this.atlTrackingService.shouldSendStartOrSuccessBusinessEvent(TrackingPoints.successBusinessEventSuccess);
    }
    this.incidentType = this.incidentStoreService.get();
  }

  showContactOpponent(): boolean {
    return this.incidentType === IncidentType.CAR;
  }

  hover(rating: number, type: string): void {
    for (let i = 0; i <= rating; i++) {
      this.starTypes[i] = type;
    }
  }
}
