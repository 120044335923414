import {Injectable} from '@angular/core';
import {IncidentType} from '../../models/incidentType';

@Injectable({
  providedIn: 'root',
})

export class IncidentTypeStoreService {
  private readonly incidentTypeKey = 'incidentType';

  constructor() {
  }

  get(): IncidentType {
    return !!localStorage.getItem(this.incidentTypeKey) ?
      IncidentType[localStorage.getItem(this.incidentTypeKey)] :
      '';
  }

  set(incidentType: string): void {
    localStorage.setItem(this.incidentTypeKey, incidentType);
  }

  getNumberOfCardsForIncident(): number {
    if (this.get() === IncidentType.CAR) {
      return 3;
    }
    return 2;
  }
}
