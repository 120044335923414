import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ContactStoreService} from '../../services/store/contact-store.service';
import {UAXPerson} from '../../models/ua-x-person';
import {AtlTrackingService} from '../../atl/atl-tracking.service';
import {TrackingPoints} from '../../atl/tracking-points';
import {RerouteToSummaryService} from '../../services/store/reroute-to-summary.service';

@Component({
  selector: 'app-accident-parties-involved',
  templateUrl: './accident-parties-involved.component.html',
  styleUrls: ['./accident-parties-involved.component.sass']
})
export class AccidentPartiesInvolvedComponent implements OnInit {

  contacts: UAXPerson[];

  queryParamAdd = {contactID: 'add'};

  constructor(private readonly router: Router,
              private readonly contactStoreService: ContactStoreService,
              private readonly atlTrackingService: AtlTrackingService,
              private readonly rerouteToSummaryService: RerouteToSummaryService) {
  }

  ngOnInit(): void {
    this.atlTrackingService.applyIfRouteValid(TrackingPoints.contactList);
    this.contacts = this.contactStoreService.get();
  }

  addAccidentParty(): void {
    this.router.navigate(['/contact'], {queryParams: this.queryParamAdd});
  }

  editAccidentParty(index: number): void {
    this.router.navigate(['/contact'], {queryParams: {contactID: index}});
  }

  removeContact(index: number): void {
    this.contacts.splice(index, 1);
  }

  hasNoContacts(): boolean {
    return this.contacts.length === 0;
  }

  getRoute(forwardRoute: any, summaryRoute: any): string {
    return this.rerouteToSummaryService.get() ? summaryRoute : forwardRoute;
  }
}
