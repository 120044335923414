import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action, Store} from '@ngrx/store';
import * as providerDataActions from './provider-data.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Router} from '@angular/router';
import {ProviderDataService} from './provider-data.service';
import {ProviderData} from '../../models/providerData';
import {environment} from '../../../environments/environment';
import {WindowService} from '../../services/window.service';
import {ProviderDataStoreService} from '../../services/store/provider-data-store.service';

@Injectable()
export class ProviderDataEffects {

  constructor(private readonly actions$: Actions,
              private readonly router: Router,
              private readonly store: Store<any>,
              private readonly providerDataService: ProviderDataService,
              private readonly windowService: WindowService,
              private readonly providerDataStore: ProviderDataStoreService) {
  }

  @Effect()
  fetchProviderData: Observable<Action> = this.actions$.pipe(
    ofType(providerDataActions.FETCH_PROVIDER_DATA),
    switchMap((action: providerDataActions.FetchProviderData) => {
      return this.providerDataService.fetchProviderData(action.customerID)
        .pipe(map((providerData: ProviderData) => {
            this.providerDataStore.set(providerData);
            return new providerDataActions.FetchProviderDataSuccess(providerData);
          }),
          catchError((err: string) => {
            console.error('ProviderDataEffects', err);
            // this.windowService.getWindow().location.href = environment.api.unfallassistent;
            return of(new providerDataActions.FetchProviderDataFailed(err));
          })
        );
    })
  );
}
