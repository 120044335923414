import {Injectable} from '@angular/core';
import {UAXPerson} from '../../models/ua-x-person';

@Injectable({
  providedIn: 'root',
})
export class ContactStoreService {
  private readonly contacts = 'contacts';

  constructor() {
  }

  get(): UAXPerson[] {
    return !!localStorage.getItem(this.contacts) ?
      JSON.parse(localStorage.getItem(this.contacts)) :
      [];
  }

  set(contact: UAXPerson[]): void {
    localStorage.setItem(this.contacts, JSON.stringify(contact));
  }
}
