import {Component, OnInit} from '@angular/core';
import {ProviderData} from '../../models/providerData';
import {ProviderDataStoreService} from '../../services/store/provider-data-store.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-registration-success',
  templateUrl: './registration-success.component.html',
  styleUrls: ['./registration-success.component.sass']
})
export class RegistrationSuccessComponent implements OnInit {
  starTypes = ['star-o', 'star-o', 'star-o', 'star-o', 'star-o'];
  providerData: ProviderData;

  withTokenPrefix = false;

  constructor(
    private readonly providerDataStoreService: ProviderDataStoreService,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.providerData = this.providerDataStoreService.get();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.withTokenPrefix = params.withTokenPrefix === 'true';
    });
  }

  hover(rating: number, type: string): void {
    for (let i = 0; i <= rating; i++) {
      this.starTypes[i] = type;
    }
  }
}
