export const environment = {
  stage: 'dev',
  enableStepGuard: true,
  production: false,
  api: {
    action: 'api-uax/finalizeUAAccelerator',
    providerData: 'api-uax/providerData',
    contractData: 'api-uax/contractData',
    register: 'api-uax/register',
    reportError: 'api-uax/reportError',
    unfallassistent: 'https://kfz-schaden-mobile-dev.allianz.de/?AZMEDID=Apps_AP-Webapp_AN-Unfallassistent.X_TH-Redirect.UA.Web_KA-perma|open|aFNOL_TP-Textlink_FM-Textlink_LT-selfservice_UR-UA.X.redirect.UA.Web_KL-/redirect.UA.Web',
  }
};
