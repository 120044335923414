<div class="nx-margin-bottom-3xl">
  <div nxLayout="grid maxwidth" class="nx-margin-bottom-m">
    <app-card-header [showBackButton]="true"
                     [title]="'summary.title' | translate">
    </app-card-header>
    <form (ngSubmit)="onSubmit()">
      <div nxRow nxRowJustify="start,start,center">

        <div *ngIf="showContact()" [nxCol]='"12,12,8,8"'>
          <nx-card *ngFor="let contact of contacts; let i = index">
            <app-edit-icon-headline [headLine]="getTitle('summary.contactTitle' | translate, i)"
                                    [route]="'summary.contactEditRoute' | translate"
                                    [queryParam]="i"
            ></app-edit-icon-headline>
            <p nxCopytext="small" class="nx-margin-0">{{'summary.contactSalutation' | translate}}</p>
            <p nxCopytext="normal" class="nx-margin-bottom-2xs">{{contact.salutation}}</p>
            <p *ngIf="!contact.companySelected" nxCopytext="small"
               class="nx-margin-0">{{'summary.contactFirstName' | translate}}</p>
            <p *ngIf="!contact.companySelected" nxCopytext="normal"
               class="nx-margin-bottom-2xs">{{contact.firstname}}</p>
            <p *ngIf="!contact.companySelected" nxCopytext="small"
               class="nx-margin-0">{{'summary.contactLastName' | translate}}</p>
            <p *ngIf="!contact.companySelected" nxCopytext="normal"
               class="nx-margin-bottom-2xs">{{contact.lastname}}</p>
            <p *ngIf="contact.companySelected" nxCopytext="small"
               class="nx-margin-0">{{'summary.contactCompanyName' | translate}}</p>
            <p *ngIf="contact.companySelected" nxCopytext="normal"
               class="nx-margin-bottom-2xs">{{contact.companyname}}</p>
            <p *ngIf="contact.companySelected" nxCopytext="small"
               class="nx-margin-0">{{'summary.contactDriverName' | translate}}</p>
            <p *ngIf="contact.companySelected" nxCopytext="normal"
               class="nx-margin-bottom-2xs">{{contact.companydriver}}</p>
            <p nxCopytext="small" class="nx-margin-0">{{'summary.contactPhone' | translate}}</p>
            <p nxCopytext="normal" class="nx-margin-bottom-2xs">{{contact.phone}}</p>
            <p nxCopytext="small" class="nx-margin-0">{{'summary.contactEmail' | translate}}</p>
            <p nxCopytext="normal" class="nx-margin-bottom-2xs">{{contact.email}}</p>
            <p nxCopytext="small" class="nx-margin-0">{{'summary.contactLicensePlate' | translate}}</p>
            <p nxCopytext="normal" class="nx-margin-bottom-2xs">{{contact.licenseplate}}</p>
          </nx-card>
        </div>
        <div *ngIf="imageList.length > 0" [nxCol]='"12,12,8,8"'>
          <nx-card>
            <app-edit-icon-headline [headLine]="'summary.fotoTitle' | translate"
                                    [route]="'summary.fotoEditRoute' | translate"
            ></app-edit-icon-headline>
            <div nxRow nxRowJustify="start">
              <div *ngFor="let image of imageList; let index = index">
                <div class="nx-margin-left-s">
                  <figure nxFigure>
                    <div *ngIf="image.isImage" [style.background-image]="'url(' + image.src + ')'"
                         class="image-frame"
                    ></div>
                    <nx-icon class="image-frame cy-preview" *ngIf="!image.isImage" name='pdf' size="l"></nx-icon>
                  </figure>
                </div>
              </div>
            </div>
          </nx-card>
        </div>
        <div *ngIf="information" [nxCol]='"12,12,8,8"'>
          <nx-card>
            <app-edit-icon-headline [headLine]="'summary.information' | translate"
                                    [route]="'summary.informationEditRoute' | translate"
            ></app-edit-icon-headline>
            <p nxCopytext="small" class="nx-margin-0">{{'summary.informationLabel' | translate}}</p>
            <p nxCopytext="normal" class="nx-margin-bottom-2xs"
               id="cy-summary-information">{{information}}</p>
          </nx-card>
        </div>
        <div [nxCol]='"12,12,8,8"'>
          <nx-card>
            <app-edit-icon-headline [headLine]="'summary.readyToDriveTitle' | translate"
                                    [route]="'summary.readyToDriveEditRoute' | translate"
            ></app-edit-icon-headline>
            <p nxCopytext="small" class="nx-margin-0">{{'summary.readyToDriveHelpNeeded' | translate}}</p>
            <p nxCopytext="normal" class="nx-margin-bottom-2xs" *ngIf="!isReadyToDrive"
               id="cy-summary-ready-to-drive-help-needed-yes">{{'summary.readyToDriveHelpNeededYes' | translate}}</p>
            <p nxCopytext="normal" class="nx-margin-bottom-2xs" *ngIf="isReadyToDrive"
               id="cy-summary-ready-to-drive-help-needed-no">{{'summary.readyToDriveHelpNeededNo' | translate}}</p>
          </nx-card>
        </div>
      </div>
      <app-card-buttons [forward]="'summary.forward' | translate">
      </app-card-buttons>
    </form>
  </div>
</div>
