import {Action} from '@ngrx/store';
import {ProviderData} from '../../models/providerData';

export const FETCH_PROVIDER_DATA = '[PROVIDER_DATA] FETCH PROVIDER DATA';
export const FETCH_PROVIDER_DATA_SUCCESS = '[PROVIDER_DATA] RECEIVED PROVIDER DATA';
export const FETCH_PROVIDER_DATA_FAILED = '[PROVIDER_DATA] FETCH PROVIDER DATA FAILED';

export class FetchProviderData implements Action {
  readonly type = FETCH_PROVIDER_DATA;

  constructor(public customerID: string) {
  }
}

export class FetchProviderDataSuccess implements Action {
  readonly type = FETCH_PROVIDER_DATA_SUCCESS;

  constructor(public providerData: ProviderData) {
  }
}

export class FetchProviderDataFailed implements Action {
  readonly type = FETCH_PROVIDER_DATA_FAILED;

  constructor(public error: string) {
  }
}

export type Actions =
  | FetchProviderData
  | FetchProviderDataFailed
  | FetchProviderDataSuccess;
