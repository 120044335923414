import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {StartComponent} from './components/start/start.component';
import {ConfirmationComponent} from './components/confirmation/confirmation.component';
import {RegistrationComponent} from './components/registration/registration.component';
import {FalsePositiveComponent} from './components/false-positive/false-positive.component';
import {TipComponent} from './components/tip/tip.component';
import {InjuryComponent} from './components/injury/injury.component';
import {IncidentComponent} from './components/incident/incident.component';
import {GuiltComponent} from './components/guilt/guilt.component';
import {ContactComponent} from './components/contact/contact.component';
import {PhotoComponent} from './components/photo/photo.component';
import {ReadyComponent} from './components/ready/ready.component';
import {AnimalTipsComponent} from './components/tips-animal/tips-animal.component';
import {SummaryComponent} from './components/summary/summary.component';
import {SuccessComponent} from './components/success/success.component';
import {AdditionalInformationComponent} from './components/additional-information/additional-information.component';
import {InformationComponent} from './components/information/information.component';
import {AccidentPartiesInvolvedComponent} from './components/accident-parties-involved/accident-parties-involved.component';
import {TrackingPoints} from './atl/tracking-points';
import {StepGuard} from './guards/step-guard.service';
import {RegistrationSuccessComponent} from './components/registration-success/registration-success.component';

const routes: Routes = [
  {path: TrackingPoints.confirmation, component: ConfirmationComponent,
    canActivate: [StepGuard],
    data: {
      backRoute: '/',
      allowedPredecessors: []
    }
  },
  {path: TrackingPoints.registration, component: RegistrationComponent,
    canActivate: [StepGuard],
    data: {
      backRoute: '/',
      allowedPredecessors: []
    }
  },
  {path: TrackingPoints.registrationSuccess, component: RegistrationSuccessComponent,
    canActivate: [StepGuard],
    data: {
      backRoute: '/',
      allowedPredecessors: []
    }
  },
  {path: TrackingPoints.start, component: StartComponent,
    canActivate: [StepGuard],
    data: {
      backRoute: '/',
      allowedPredecessors: []
    }
  },
  {path: TrackingPoints.falsePositive, component: FalsePositiveComponent,
    canActivate: [StepGuard],
    data: {
      backRoute: '/',
      allowedPredecessors: [
        TrackingPoints.start
      ]
    }
  },
  {path: TrackingPoints.tip, component: TipComponent,
    canActivate: [StepGuard],
    data: {
      backRoute: '/',
      allowedPredecessors: [
        TrackingPoints.start,
        TrackingPoints.injury,
      ]
    }
  },
  {path: TrackingPoints.tipAnimal, component: AnimalTipsComponent,
    canActivate: [StepGuard],
    data: {
      backRoute: '/',
      allowedPredecessors: [
        TrackingPoints.incident,
        TrackingPoints.photo,
      ]
    }
  },
  {path: TrackingPoints.injury, component: InjuryComponent,
    canActivate: [StepGuard],
    data: {
      backRoute: '/',
      allowedPredecessors: [
        TrackingPoints.tip,
        TrackingPoints.incident
      ]
    }
  },
  {path: TrackingPoints.incident, component: IncidentComponent,
    canActivate: [StepGuard],
    data: {
      backRoute: '/',
      allowedPredecessors: [
        TrackingPoints.injury,
        TrackingPoints.tipAnimal,
        TrackingPoints.photo,
        TrackingPoints.guilt,
      ]
    }
  },
  {path: TrackingPoints.guilt, component: GuiltComponent,
    canActivate: [StepGuard],
    data: {
      backRoute: '/',
      allowedPredecessors: [
        TrackingPoints.incident,
        TrackingPoints.contact,
      ]
    }
  },
  {path: TrackingPoints.contact, component: ContactComponent,
    canActivate: [StepGuard],
    data: {
      backRoute: '/',
      allowedPredecessors: [
        TrackingPoints.guilt,
        TrackingPoints.contactList,
        TrackingPoints.summary
      ]
    }
  },
  {path: TrackingPoints.contactList, component: AccidentPartiesInvolvedComponent,
    canActivate: [StepGuard],
    data: {
      backRoute: '/',
      allowedPredecessors: [
        TrackingPoints.contact,
        TrackingPoints.photo,
      ]
    }
  },
  {path: TrackingPoints.photo, component: PhotoComponent,
    canActivate: [StepGuard],
    data: {
      backRoute: '/',
      allowedPredecessors: [
        TrackingPoints.tipAnimal,
        TrackingPoints.additionalInformation,
        TrackingPoints.incident,
        TrackingPoints.summary,
        TrackingPoints.contactList,
      ]
    }
  },
  {path: TrackingPoints.additionalInformation, component: AdditionalInformationComponent,
    canActivate: [StepGuard],
    data: {
      backRoute: '/',
      allowedPredecessors: [
        TrackingPoints.photo,
        TrackingPoints.ready,
        TrackingPoints.information
      ]
    }
  },
  {path: TrackingPoints.information, component: InformationComponent,
    canActivate: [StepGuard],
    data: {
      backRoute: '/',
      allowedPredecessors: [
        TrackingPoints.ready,
        TrackingPoints.summary,
        TrackingPoints.additionalInformation
      ]
    }
  },
  {path: TrackingPoints.ready, component: ReadyComponent,
    canActivate: [StepGuard],
    data: {
      backRoute: '/',
      allowedPredecessors: [
        TrackingPoints.information,
        TrackingPoints.summary,
        TrackingPoints.additionalInformation,
      ]
    }
  },
  {path: TrackingPoints.summary, component: SummaryComponent,
    canActivate: [StepGuard],
    data: {
      backRoute: '/',
      allowedPredecessors: [
        TrackingPoints.ready,
        TrackingPoints.information,
        TrackingPoints.photo,
        TrackingPoints.contactList
      ]
    }
  },
  {path: TrackingPoints.success, component: SuccessComponent,
    canActivate: [StepGuard],
    data: {
      backRoute: '/',
      allowedPredecessors: [
        TrackingPoints.summary,
      ]
    }
  },
  {path: '**', redirectTo: TrackingPoints.confirmation}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
