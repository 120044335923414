import {Component, OnInit} from '@angular/core';
import {AtlTrackingService} from '../../atl/atl-tracking.service';
import {TrackingPoints} from '../../atl/tracking-points';

@Component({
  selector: 'app-tips-animal',
  templateUrl: './tips-animal.component.html',
  styleUrls: ['./tips-animal.component.css'],
})
export class AnimalTipsComponent implements OnInit {
  constructor(private readonly atlTrackingService: AtlTrackingService) {
  }

  ngOnInit(): void {
    this.atlTrackingService.applyIfRouteValid(TrackingPoints.tipAnimal);
  }
}
