<div>
  <footer nx-footer>
    <div nxLayout="grid maxwidth">
      <div nxRow nxRowJustify="start">
        <nx-footer-copyright>
          {{'footer.copyright' | translate}} {{year}}
        </nx-footer-copyright>
        <nx-footer-navigation>
          <nx-footer-link>
            <a href="https://www.allianz.de/impressum/">{{'footer.imprint' | translate}}</a>
          </nx-footer-link>
          <nx-footer-link>
            <a href="https://www.allianz.de/datenschutz/">{{'footer.privacy-statement' | translate}}</a>
          </nx-footer-link>
          <nx-footer-link>
            <a href="https://www.allianz.de/nutzungsbedingungen/">{{'footer.terms-of-use' | translate}}</a>
          </nx-footer-link>
        </nx-footer-navigation>
      </div>
    </div>
  </footer>
</div>
