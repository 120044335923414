<header nx-header>
  <div nxLayout="grid maxwidth">
    <div nxRow nxRowJustify="start" class="nx-margin-y-m">
      <div class="brand-column" nxCol="8,8,8,12" nxColOffset="2,2,2,0">
        <nx-header-brand class="nx-margin-right-0">
          <figure nxFigure>
            <img src='assets/allianz.svg' alt="Allianz Global Brand Logo" role="none">
          </figure>
        </nx-header-brand>
        <div style="display: flex; align-items: center">
          <nx-header-brand class="nx-margin-right-0 az-contact">
            <nx-link>
              <a onclick="window.location='tel:08000000021'">
                <nx-icon name="phone" class="nx-margin-right-s" size="s"></nx-icon>
                0800 - 0000 0 21
              </a>
            </nx-link>
          </nx-header-brand>

          <!-- language picker on desktop -->
          <nx-header-brand class="nx-margin-right-0 language-picker">
            <button nxButton="small tertiary"
                    class="button"
                    [nxContextMenuTriggerFor]="menu"
                    ngDefaultControl
                    type="button"
                    aria-label="Open menu">
              <nx-icon class="u-margin-right-5" name="product-world-globe" size="small"></nx-icon>
              {{selectedLanguage.name}}
            </button>
            <nx-context-menu #menu="nxContextMenu">
              <button
                *ngFor="let language of languages"
                nxContextMenuItem
                type="button"
                (click)="setLang(language.id, language.name)">
                {{language.name}}
              </button>
            </nx-context-menu>
          </nx-header-brand>
        </div>
      </div>
      <div nxCol="2"
           class="help-icon u-text-align-right"
           *ngIf="showCallIconOnMobile()">
        <nx-link>
          <a onclick="window.location='tel:08000000021'">
            <nx-icon name="phone" class="nx-margin-right-s" size="s"></nx-icon>
          </a>
        </nx-link>
      </div>
    </div>
  </div>

  <!-- language picker on responsive screen -->
  <nx-header-brand class="nx-margin-right-0 language-picker-responsive">
    <button nxButton="small tertiary"
            class="button"
            [nxContextMenuTriggerFor]="menu"
            ngDefaultControl
            type="button"
            aria-label="Open menu">
      <nx-icon class="u-margin-right-5" name="product-world-globe" size="small"></nx-icon>
      {{selectedLanguage.name}}
    </button>
    <nx-context-menu #menu="nxContextMenu">
      <button
        *ngFor="let language of languages"
        nxContextMenuItem
        type="button"
        (click)="setLang(language.id, language.name)">
        {{language.name}}
      </button>
    </nx-context-menu>
  </nx-header-brand>
</header>
