import {NgModule} from '@angular/core';
import { NxHeaderModule } from '@aposin/ng-aquila/header';
import { NxLinkModule } from '@aposin/ng-aquila/link';
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { NxHeadlineModule } from '@aposin/ng-aquila/headline';
import { NxButtonModule } from '@aposin/ng-aquila/button';
import { NxIconModule } from '@aposin/ng-aquila/icon';
import { NxCardModule } from '@aposin/ng-aquila/card';
import { NxCopytextModule } from '@aposin/ng-aquila/copytext';
import { NxListModule } from '@aposin/ng-aquila/list';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { NxImageModule } from '@aposin/ng-aquila/image';
import { NxDropdownModule } from '@aposin/ng-aquila/dropdown';
import { NxPopoverModule } from '@aposin/ng-aquila/popover';
import { NxFormfieldModule } from '@aposin/ng-aquila/formfield';
import { NxFooterModule } from '@aposin/ng-aquila/footer';
import { NxModalModule } from '@aposin/ng-aquila/modal';
import { NxSwitcherModule } from '@aposin/ng-aquila/switcher';
import { NxSidebarModule } from '@aposin/ng-aquila/sidebar';
import { NxActionModule } from '@aposin/ng-aquila/action';
import { NxMessageModule } from '@aposin/ng-aquila/message';
import { NdbxIconModule } from '@allianz/ngx-ndbx/icon';
import {NxPhoneInputModule} from '@aposin/ng-aquila/phone-input';

@NgModule({
  imports: [
    NxHeaderModule,
    NxLinkModule,
    NxGridModule,
    NxHeadlineModule,
    NxButtonModule,
    NxIconModule,
    NxCardModule,
    NxCopytextModule,
    NxListModule,
    NxInputModule,
    NxImageModule,
    NxDropdownModule,
    NxPopoverModule,
    NxFormfieldModule,
    NxFooterModule,
    NxActionModule,
    NxSidebarModule,
    NxSwitcherModule,
    NxMessageModule,
    NdbxIconModule,
    NxPhoneInputModule,
  ],
  exports: [
    NxHeaderModule,
    NxLinkModule,
    NxGridModule,
    NxHeadlineModule,
    NxButtonModule,
    NxIconModule,
    NxCardModule,
    NxCopytextModule,
    NxListModule,
    NxInputModule,
    NxImageModule,
    NxModalModule,
    NxDropdownModule,
    NxPopoverModule,
    NxFormfieldModule,
    NxFooterModule,
    NxActionModule,
    NxSidebarModule,
    NxSwitcherModule,
    NxMessageModule,
    NdbxIconModule,
    NxPhoneInputModule
  ]
})
export class NdbxSharedModule {
}
