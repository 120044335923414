<div *ngIf="!isLoading; else loadingSpinner" class="nx-margin-bottom-3xl">
  <div nxLayout="grid maxwidth">
    <app-card-header [showBackButton]="false"
                     [subTitle]="'registration.subtitle' | translate"
                     [title]="'registration.title' | translate">
    </app-card-header>
    <div nxRow
         nxRowJustify="start,start,center"
         nxRowAlignItems="start">
      <div nxCol="12,12,8,6">
        <nx-card class="card">
          <div class="row">
            <div class="key">{{'registration.form.contractnumber' | translate}}</div>
            <div class="value">{{registrationData?.policyNumber}}</div>
          </div>
          <div class="row">
            <div class="key">{{'registration.form.mobilenumber' | translate}}</div>
            <div class="value">+49{{userInput?.mobileNumber}}</div>
          </div>
          <div class="row">
            <div class="key">{{'registration.form.licenseplate' | translate}}</div>
            <div class="value">{{userInput?.unrevisedLicensePlateFirst}}-{{userInput?.licensePlateSecond}}
              {{userInput.licensePlateThird}}</div>
          </div>
        </nx-card>
      </div>
    </div>
    <div nxRow
         nxRowJustify="center,center,center"
         nxRowAlignItems="center"
         *ngIf="withConfirmationCheckbox">
      <div nxCol="3,3,2,1">
        <nx-checkbox [(ngModel)]="confirmed">
        </nx-checkbox>
      </div>
      <div nxCol="7,7,5,4">
        <p class="checkbox-text" *ngIf="translate.currentLang === 'de'">
          <a href="{{termsOfUseUrl}}" target="_blank">Nutzungsbedingungen</a> und
          <a href="{{dataProtectionUrl}}" target="_blank">Datenschutzhinweise</a> bestätigen
        </p>
        <p class="checkbox-text" *ngIf="translate.currentLang === 'en'">
          Confirm <a href="{{termsOfUseUrl}}" target="_blank">Terms of Use</a> and
          <a href="{{dataProtectionUrl}}" target="_blank">Data Protection Information</a>
        </p>
      </div>
    </div>
    <app-card-buttons (emitIsReadyToDrive)="register()"
                      [forward]="'registration.confirmation' | translate"
                      [disabled]="withConfirmationCheckbox && !confirmed">
    </app-card-buttons>
  </div>
</div>

<ng-template #loadingSpinner>
  <nx-spinner nxSize="large" class="centered"></nx-spinner>
</ng-template>
