import {Injectable} from '@angular/core';
import {UAXPerson} from '../../models/ua-x-person';
import {ProviderData} from '../../models/providerData';

@Injectable({
  providedIn: 'root',
})
export class ProviderDataStoreService {

  providerData: ProviderData;

  constructor() {
  }

  get(): ProviderData {
    return this.providerData;
  }

  set(providerData: ProviderData): void {
    this.providerData = providerData;
  }
}
