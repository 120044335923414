import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public event = new EventEmitter();

  constructor() {}

  public success(message: string, title = 'Erfolg'): void {
    this.event.emit({message, title, context: 'success'});
  }
  public error(message: string, title = 'Fehler'): void {
    this.event.emit({message, title, context: 'error'});
  }
  public warn(message: string, title = 'Warnung'): void {
    this.event.emit({message, title, context: 'warning'});
  }
}
