import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action, Store} from '@ngrx/store';
import * as imapActoins from './mail.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {MailService} from './mail.service';
import {of} from 'rxjs/internal/observable/of';
import {Router} from '@angular/router';
import {NotificationService} from '../../components/notification/notification.service';

@Injectable()
export class MailEffects {

  constructor(private readonly actions$: Actions,
              private readonly router: Router,
              private readonly store: Store<any>,
              private readonly imapService: MailService,
              private readonly notification: NotificationService) {
  }

  @Effect()
  sendMail: Observable<Action> = this.actions$.pipe(
    ofType(imapActoins.SEND_MAIL),
    switchMap((action: imapActoins.SendMail) => {
      return this.imapService.sendMail(action.body, action.attachments)
        .pipe(map(() => {
            this.router.navigate([action.targetRoute]);
            return new imapActoins.MailSendSuccess();
          }),
          catchError((err: string) => {
            this.notification.error('Ihre Anfrage konnte nicht bearbeitet werden, bitte versuchen Sie es später noch einmal.');
            return of(new imapActoins.MailSendFail(err));
          })
        );
    })
  );
}
