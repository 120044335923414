<div nxLayout="grid maxwidth">
  <app-card-header [showBackButton]="true"
                   [subTitle]="'guilt.subTitle' | translate"
                   [title]="'guilt.title' | translate">
  </app-card-header>
  <app-figure [source]="'guilt.img' | translate" minHeight="207.99px">
  </app-figure>
  <app-card-buttons [forwardRoute]="'guilt.forwardRoute' | translate"
                    [forward]="'guilt.forward' | translate">
  </app-card-buttons>
</div>
