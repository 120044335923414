import * as providerDataActoins from './provider-data.actions';
import {createFeatureSelector} from '@ngrx/store';
import {ProviderData} from '../../models/providerData';

export interface ProviderDataState {
  isLoading: boolean;
  providerData: ProviderData;
}

const initialState: ProviderDataState = {
  isLoading: false,
  providerData: {} as ProviderData
};

export function ProviderDataReducer(state = initialState, action: providerDataActoins.Actions): ProviderDataState {
  switch (action.type) {
    case providerDataActoins.FETCH_PROVIDER_DATA:
      return {isLoading: true} as ProviderDataState;
    case providerDataActoins.FETCH_PROVIDER_DATA_SUCCESS:
      return {isLoading: false, providerData: action.providerData} as ProviderDataState;
    case providerDataActoins.FETCH_PROVIDER_DATA_FAILED:
      return {isLoading: false} as ProviderDataState;
    default:
      return state;
  }
}

export const selectedProviderDataState = createFeatureSelector('selectedProviderDataState');
