import {Injectable} from '@angular/core';
import {storageKey} from '../models/enum/storageKey';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {
  }

  get(key: string): any {
    const value = localStorage.getItem(key);
    if (value) {
      return this.deserialize(value);
    } else {
      return undefined;
    }
  }

  set(key: string, value: any): boolean {
    // only store plain key value pairs like literals
    localStorage.setItem(key, this.serialize(value));
    return true;
  }

  delete(key: string): void {
    localStorage.removeItem(key);
  }

  private serialize(data: any): string {
    return JSON.stringify(data);
  }

  private deserialize(data: string): any {
    try {
      return JSON.parse(data);
    } catch (error) {
      return data;
    }
  }

  clearAllExceptCookiesAccepted(): void {
    for (const key in localStorage) {
      if (key !== storageKey.trackingCookieAccepted && key !== storageKey.marketingCookieAccepted) {
        this.delete(key);
      }
    }
  }
}
