import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {InformationService} from '../../services/store/information.service';
import {AtlTrackingService} from '../../atl/atl-tracking.service';
import {TrackingPoints} from '../../atl/tracking-points';
import {RerouteToSummaryService} from '../../services/store/reroute-to-summary.service';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.sass'],
})
export class InformationComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  formBuilder: FormBuilder;
  information: string;

  constructor(private readonly router: Router,
              private readonly informationService: InformationService,
              private readonly atlTrackingService: AtlTrackingService,
              private readonly rerouteToSummaryService: RerouteToSummaryService) {
  }

  ngOnInit(): void {
    this.atlTrackingService.applyIfRouteValid(TrackingPoints.information);
    this.information = this.informationService.get();
    this.formBuilder = new FormBuilder();
    this.formGroup = this.formBuilder.group({
      information: [this.information, Validators.required]
    });
  }

  onSubmit(summaryRoute: string, forwardRoute: string): void {
    this.storeInformation();
    this.router.navigateByUrl(this.getRoute(summaryRoute, forwardRoute));
  }

  getRoute(summaryRoute: string, forwardRoute: string): string {
    return this.rerouteToSummaryService.get() ? summaryRoute : forwardRoute;
  }

  ngOnDestroy(): void {
    this.storeInformation();
  }

  private storeInformation(): void {
    this.informationService.set(this.formGroup.value.information);
  }
}
