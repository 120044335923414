import {Injectable} from '@angular/core';
// @ts-ignore
import imageCompression from 'browser-image-compression';
import {from, Observable} from 'rxjs';
import {Options} from '../models/compression-options';

@Injectable({
  providedIn: 'root'
})
export class ImageCompressionService {

  constructor() {
  }

  compress(file: File, options: Options): Observable<any> {
    return from(this.promiseToObservable(file, options));
  }

  private promiseToObservable(file: File, options: Options): Promise<any> {
    return imageCompression(file, options);
  }
}
