export enum EventName {
  pageview = 'pageview', // url changes
  start = 'start', // start page
  quote = 'quote',
  success = 'success', //
  step = 'step',
  download = 'download',
  interaction = 'interaction', // button click to SOK, VOC link e.g.
  techinfo = 'techinfo',
  AtlDefaultEvent = 'AtlDefaultEvent'
}

export enum AtlTrackingKey {
  process_processLocation = 'process.processLocation',
  process_stepName = 'process.stepName',
  process_stepNumber = 'process.stepNumber',
  process_processName = 'process.processName',
  page_pageInfo_pageName = 'page.pageInfo.pageName',
  page_pageInfo_pageID = 'page.pageInfo.pageID',
  azde_genericApp_screenName = 'azde.genericApp.screenName',
  azde_genericApp_appName = 'azde.genericApp.appName',
  error = 'error',
  azde_pagesource_sourceelement_name = 'azde.pagesource.sourceelement.name',
  azde_pagesource_sourceelement_type = 'azde.pagesource.sourceelement.type',
  azde_pagesource_sourceelement_position = 'azde.pagesource.sourceelement.position',
  azde_pagesource_component = 'azde.pagesource.component',
  Tab = 'Tab',
  Link = 'Link'
}
