import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {
  RegistrationRequestData,
  RegistrationService,
  StellantisRegistrationRequestData
} from '../../services/registration.service';
import {RegistrationData} from '../../models/registrationData';
import {TranslateService} from '@ngx-translate/core';
import {ErrorService} from '../../services/error.service';
import {ErrorType, ErrorCode} from '../../models/errorData';
import * as moment from 'moment';

enum TokenPrefix {
  STELLANTIS = '12c1' // sha3_256 [:4]
}

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.sass']
})
export class RegistrationComponent implements OnInit {
  registrationData: RegistrationData;
  userInput: Params;
  isLoading = true;
  private token: string;

  private termsOfUseUrl = 'https://www.allianz.de/content/dam/onemarketing/azde/azd/pdfs/service/schaden-melden/kfz/unfallmelder/vernetztes-fahrzeug/nutzungsbedingungen.pdf';
  private dataProtectionUrl = 'https://www.allianz.de/content/dam/onemarketing/azde/azd/pdfs/service/schaden-melden/kfz/unfallmelder/vernetztes-fahrzeug/datenschutzhinweise.pdf';
  private withConfirmationCheckbox = false;
  private confirmed = false;

  constructor(private readonly route: ActivatedRoute,
              private readonly registrationService: RegistrationService,
              private readonly errorService: ErrorService,
              private readonly router: Router,
              private readonly translate: TranslateService) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams.token) {
        this.token = queryParams.token;
        this.checkForTokenPrefix();
        this.registrationService.fetchRegistrationData(queryParams)
          .subscribe(result => {
            this.userInput = queryParams;
            this.registrationData = result;
            this.isLoading = false;
          }, error => {
            const updatedQueryParamsWithError = {...queryParams};
            updatedQueryParamsWithError.errorCode = error.status;
            updatedQueryParamsWithError.errorMessage = error.error;
            this.router.navigate(['/confirmation'], {queryParams: updatedQueryParamsWithError});
          });
      } else {
        const updatedQueryParamsWithError = {...queryParams};
        updatedQueryParamsWithError.errorCode = 404;
        this.router.navigate(['/confirmation'], {queryParams: updatedQueryParamsWithError});
      }
    });
  }

  checkForTokenPrefix(): void {
    if (this.token.length !== 12) {
      return;
    }

    if (this.token.startsWith(TokenPrefix.STELLANTIS)) {
      this.withConfirmationCheckbox = true;
    }
  }

  register(): void {
    const registrationData: RegistrationRequestData | StellantisRegistrationRequestData = {
      mobileNumber: '+49' + this.userInput.mobileNumber,
      licensePlate: this.registrationData.licensePlate,
      firstName: this.registrationData.firstName,
      lastName: this.registrationData.lastName
    };
    if (this.withConfirmationCheckbox) {
      // registrationData: StellantisRegistrationRequestData
      const stellantisRegistrationData = (registrationData as StellantisRegistrationRequestData);
      stellantisRegistrationData.consentConnectedCar = this.confirmed;
      stellantisRegistrationData.consentConnectedCarVersion = '1.0';
      stellantisRegistrationData.consentConnectedCarTs = moment().unix();
    }
    this.registrationService.register(this.token, registrationData).subscribe();
    this.router.navigate(['/registrationSuccess'],
      {queryParams: {withTokenPrefix: this.withConfirmationCheckbox}});
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event): void {
    this.errorService.reportError({
      type: ErrorType.WARN,
      code: ErrorCode.REGISTRATION,
      token: this.token,
      url: window.location.host,
    }).subscribe();
  }
}
