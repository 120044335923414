import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {IEnvironment} from '../models/IEnvironment';
import {ENV} from '../../environments/environment.provider';

@Injectable({
  providedIn: 'root'
})
export class StepGuard implements CanActivate {
  oldPath = '';

  constructor(protected readonly router: Router,
              @Inject(ENV) private readonly env: IEnvironment) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const newPath = next.routeConfig.path;
    const allowedPredecessors = next.data.allowedPredecessors ? next.data.allowedPredecessors : [];

    if (!this.env.enableStepGuard || allowedPredecessors.length === 0) {
      this.oldPath = newPath;
      return true;
    }

    allowedPredecessors.push('Error');

    if (allowedPredecessors.find(allowedPredecessor => this.oldPath.startsWith(allowedPredecessor))) {
      this.oldPath = newPath;
      return true;
    }

    this.backToHomePage(next.data.backRoute);
    return false;
  }

  protected backToHomePage(backRoute: string): void {
    this.router.navigate([backRoute]);
  }
}
