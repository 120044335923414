import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { NxDialogService } from '@aposin/ng-aquila/modal';
import {AtlConfigService} from '../../atl/atl-config.service';
import {AtlTrackingService} from '../../atl/atl-tracking.service';
import {environment} from '../../../environments/environment';
import { NxModalConfig } from '@aposin/ng-aquila/modal/dialog/modal-config';
import {TrackingPoints} from '../../atl/tracking-points';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../services/storage.service';
import {storageKey, storageValue} from '../../models/enum/storageKey';

export const nxModalConfig: NxModalConfig = {
  width: 'calc(100px + 65vw)',
  maxWidth: 'calc(100vh - 31px)',
  maxHeight: '91vh',
  disableClose: true
};

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.css']
})
export class CookieComponent implements OnInit, AfterViewInit {

  @ViewChild('cookie') cookieTemplate: TemplateRef<any>;
  showCookieSettings = false;

  id: string;
  title: string;  // cookie.settings.tabs.${id}.title
  info: string;   // cookie.settings.tabs.${id}.info
  active: string; // cookie.settings.tabs.${id}.active

  enableTracking = true;
  enableMarketing = false;
  cookieAccepted: boolean;

  translations = [];

  constructor(private readonly dialogService: NxDialogService,
              private readonly atlConfigService: AtlConfigService,
              private readonly atlTrackingService: AtlTrackingService,
              private readonly translateService: TranslateService,
              private readonly storageService: StorageService
  ) {
    this.cookieAccepted = !!storageService.get(storageKey.trackingCookieAccepted)
      || !!storageService.get(storageKey.marketingCookieAccepted);
  }

  ngOnInit(): void {
    this.title = this.translateService.instant('cookie.settings.tabs.yourDataPrivacy.title');
    this.info = this.translateService.instant('cookie.settings.tabs.yourDataPrivacy.info');
    this.translations.push(
      {
        id: this.translateService.instant('cookie.settings.tabs.yourDataPrivacy.id'),
        label: this.translateService.instant('cookie.settings.tabs.yourDataPrivacy.title'),
      },
      {
        id: this.translateService.instant('cookie.settings.tabs.technicalNecessaryCookies.id'),
        label: this.translateService.instant('cookie.settings.tabs.technicalNecessaryCookies.title'),
      },
      {
        id: this.translateService.instant('cookie.settings.tabs.cookiesForStatisticUsage.id'),
        label: this.translateService.instant('cookie.settings.tabs.cookiesForStatisticUsage.title'),
      },
      {
        id: this.translateService.instant('cookie.settings.tabs.cookiesForMarketingUsage.id'),
        label: this.translateService.instant('cookie.settings.tabs.cookiesForMarketingUsage.title'),
      },
      {
        label: this.translateService.instant('cookie.settings.tabs.moreInformation.title'),
        url: this.translateService.instant('cookie.settings.tabs.moreInformation.url'),
      }
    );
  }

  ngAfterViewInit(): void {
    if (!this.cookieAccepted) {
      this.dialogService.open(this.cookieTemplate,
        nxModalConfig
      );
    } else {
      this.startAtl(
        this.storageService.get(storageKey.trackingCookieAccepted) === storageValue.accepted,
        this.storageService.get(storageKey.marketingCookieAccepted) === storageValue.accepted
      );
    }
  }

  acceptAllCookiesAndCloseModal(): void {
    this.storageService.set(storageKey.trackingCookieAccepted, storageValue.accepted);
    this.storageService.set(storageKey.marketingCookieAccepted, storageValue.accepted);
    this.closeDialog();
    this.startAtl();
  }

  private closeDialog(): void {
    this.dialogService.closeAll();
  }

  startAtl(enableTracking = true, enableMarketing = true): void {
    this.atlConfigService.handleSatTrackCookie();
    this.atlConfigService.createDefaultEnvironment(enableTracking, enableMarketing, environment.stage);
    this.storageService.clearAllExceptCookiesAccepted();
    if (this.atlTrackingService.initTrackingServiceForPageView()) {
      this.atlTrackingService.applyIfRouteValid(TrackingPoints.start);
    }
  }

  openCookieSettings(): void {
    this.showCookieSettings = true;
  }

  saveSelected(): void {
    this.enableTracking
      ? this.storageService.set(storageKey.trackingCookieAccepted, storageValue.accepted)
      : this.storageService.set(storageKey.trackingCookieAccepted, storageValue.notAccepted);

    this.enableMarketing
      ? this.storageService.set(storageKey.marketingCookieAccepted, storageValue.accepted)
      : this.storageService.set(storageKey.marketingCookieAccepted, storageValue.notAccepted);

    this.startAtl(this.enableTracking, this.enableMarketing);
    this.closeDialog();
  }

  onSelect(id: string): void {
    if (id) {
      this.id = id;
      this.title = this.translateService.instant(`cookie.settings.tabs.${id}.title`);
      this.info = this.translateService.instant(`cookie.settings.tabs.${id}.info`);
      const active = this.translateService.instant(`cookie.settings.tabs.${id}.active`);
      if (!active.startsWith('cookie.settings.tabs')) {
        this.active = active;
      } else {
        this.active = undefined;
      }
    }
  }
}
