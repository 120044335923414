import { Injectable } from '@angular/core';
import {UploadedFile} from '../../models/uploadedFile';

@Injectable({
  providedIn: 'root'
})
export class RerouteToSummaryService {

  rerouteToSummery = false;

  constructor() {
  }

  get(): boolean {
    return this.rerouteToSummery;
  }

  set(): void {
    this.rerouteToSummery = true;
  }
}
