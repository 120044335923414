<div nxLayout="grid maxwidth"
*ngIf="'information' | translate as information">
  <app-card-header [showBackButton]="true"
                   [title]="information.title"
                   [subTitle]="information.subtitle">
  </app-card-header>

  <form (ngSubmit)="onSubmit(information.summaryRoute, information.forwardRoute)"
        [formGroup]="formGroup"
        class="nx-margin-bottom-3xl">
    <div nxRow
         nxRowJustify="start,start,center"
         nxRowAlignItems="start">
      <div nxCol="12,12,8,6">
        <nx-formfield nxStyle="border-bottom: 0px!important">
        <textarea nxInput
                  [placeholder]="information.label"
                  formControlName="information"
                  id="cy-information">
        </textarea>
        </nx-formfield>
      </div>
    </div>

    <div nxRow
         nxRowJustify="center"
         nxRowAlignItems="center"
         class="nav-step--bottom">
      <div nxCol="12,12,6,6">
        <button
          [disabled]="formGroup.invalid"
          nxButton="primary"
          type="submit"
          id="cy-information-forward">
          {{information.forward}}
        </button>
      </div>
    </div>
  </form>
</div>
