import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ErrorData} from '../models/errorData';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private http: HttpClient) {
  }

  reportError(errorData: ErrorData): Observable<null> {
    return this.http.post<null>(
      environment.api.reportError,
      errorData);
  }
}
