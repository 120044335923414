export enum ErrorCode {
  CONFIRMATION = 'confirmation',
  REGISTRATION = 'registration',
  TOKEN_MISSING = 'tokenMissing',
}

export enum ErrorType {
  WARN = 'warn',
  ERROR = 'error',
}

export interface ErrorData {
  type: ErrorType;
  code: ErrorCode;
  token: string | null;
  url: string;
}
