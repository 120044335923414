import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {WindowService} from '../../services/window.service';

@Component({
  selector: 'app-card-buttons',
  templateUrl: './card-buttons.component.html',
  styleUrls: ['./card-buttons.component.sass']
})
export class CardButtonsComponent implements OnInit {

  @Input()
  jump: string;
  @Input()
  jumpEnabled = false;
  @Input()
  jumpLinkWithIconOnDesktop = false;
  @Input()
  jumpRoute: string;

  @Input()
  emergency: string;
  @Input()
  emergencyEnabled = false;
  @Input()
  emergencyNumber;
  @Input()
  showEmergencyIcon = false;

  @Input()
  forward: string;
  @Input()
  forwardRoute: string;

  @Input()
  disabled = false;

  @Input()
  defaultNarrowWidthOnDesktop = true;

  @Output()
  emitIsReadyToDrive = new EventEmitter<boolean>();

  constructor(private readonly windowService: WindowService) {
  }

  ngOnInit(): void {
  }

  getWidthOnDesktop(): string {
    if (this.defaultNarrowWidthOnDesktop) {
      return '6,4';
    } else {
      return '8,8';
    }
  }

  callEmergencyNumber(): void {
    this.windowService.getWindow().location.assign(`tel:${this.emergencyNumber}`);
  }

  jumpOnClick(): void {
    this.emitIsReadyToDrive.emit(false);
  }

  forwardOnClick(): void {
    this.emitIsReadyToDrive.emit(true);
  }
}
