import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {IncidentTypeStoreService} from '../../services/store/incident-type-store.service';
import { NxSelectableCardComponent } from '@aposin/ng-aquila/card';
import {IResponsiveCard} from '../../models/IResponsiveCard';
import { NxIconRegistry } from '@aposin/ng-aquila/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {AtlTrackingService} from '../../atl/atl-tracking.service';
import {TrackingPoints} from '../../atl/tracking-points';
import {IncidentType} from '../../models/incidentType';

export const INCIDENT_TYPE = 'Unfallart';
export const unfallMitKfz = 'Unfall_mit_kfz';
export const unfallMitTier = 'Unfall_mit_Tier';
export const sonstigeSchaeden = 'Sonstige_Schaeden';

@Component({
  selector: 'app-incident',
  templateUrl: './incident.component.html',
  styleUrls: ['./incident.component.sass']
})
export class IncidentComponent implements OnInit {

  selectedOption = {} as IResponsiveCard;
  @ViewChildren('nxSelectableCardComponents') nxSelectableCardComponents: QueryList<NxSelectableCardComponent>;

  constructor(private readonly incidentTypeStoreService: IncidentTypeStoreService,
              private readonly nxIconRegistry: NxIconRegistry,
              private readonly domSanitizer: DomSanitizer,
              private readonly atlTrackingService: AtlTrackingService
  ) {
    this.nxIconRegistry.addSvgIcon('other-accident',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons_incident/HitWall.svg'));
    this.nxIconRegistry.addSvgIcon('car-accident',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons_incident/Blue_Crash.svg'));
  }

  ngOnInit(): void {
    this.atlTrackingService.applyIfRouteValid(TrackingPoints.incident);
  }

  selectionChange($event, selectedOption: IResponsiveCard): void {
    if ($event.checked) {
      this.uncheckSelected();
      this.selectedOption = selectedOption;
      this.incidentTypeStoreService.set(selectedOption.incidentType);
    } else {
      this.selectedOption = {} as IResponsiveCard;
    }
  }

  uncheckSelected(): void {
    this.nxSelectableCardComponents.forEach(viewChild => {
      if (viewChild.id.endsWith(this.selectedOption.incidentType)) {
        viewChild.toggle();
      }
    });
  }

  storeIncidentType(incidentType: string): void {
    this.incidentTypeStoreService.set(incidentType);
  }
}
