<div nxLayout="grid maxwidth">
  <ng-template #cookie>
    <ng-container *ngIf="!showCookieSettings else cookieSettings">
      <div nxModalContent>
        <h3 class="u-text-align-left" nxHeadline="subsection-small">
          {{"cookie.title" | translate}}
        </h3>
        <p nxCopytext="large">
          {{"cookie.text" | translate}}
        </p>
        <nx-link class="nx-margin-bottom-m">
          <a target="_blank" rel="noopener noreferrer" [href]="'cookie.url' | translate">
            {{"cookie.link" | translate}}
          </a>
        </nx-link>
      </div>
      <div nxModalActions>
        <button id="cy-open-cookie-settings"
                class="nx-margin-top-m nx-margin-bottom-m u-padding-sm nx-margin-right-xs"
                nxButton="small secondary" type="button"
                (click)="openCookieSettings()">
          {{"cookie.open-cookie-settings" | translate}}
        </button>
        <button id="cy-cookie-accept"
                class="nx-margin-top-m nx-margin-bottom-m u-padding-sm"
                nxButton="small primary" type="button"
                (click)="acceptAllCookiesAndCloseModal()">
          {{"cookie.accept" | translate}}
        </button>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #cookieSettings>
    <div nxModalContent>
      <h3 class="u-text-align-left" nxHeadline="subsection-small">
        {{"cookie.settings.title" | translate}}
      </h3>
      <div nxRow>
        <div nxCol="12,12,6,6">
          <nx-sidebar>
            <button nxAction
                    *ngFor="let translation of translations"
                    type="button"
                    (click)="onSelect(translation.id)"
            >
              <nx-link class="nx-margin-bottom-m" *ngIf="translation.url">
                <a target="_blank" rel="noopener noreferrer" [href]="translation.url">
                  {{translation.label}}
                </a>
              </nx-link>
              <ng-container *ngIf="!translation.url">{{translation.label}}</ng-container>
            </button>
          </nx-sidebar>
        </div>
        <div nxCol="12,12,6,6" class="margin-top-32px-mobile">
          <div nxRow *ngIf="active">
            <div nxCol="7">
              <h4 class="u-text-align-left" nxHeadline="subsection-small">
                {{title}}
              </h4>
            </div>
            <div nxCol="5">
              <p nxCopytext="large" class="green nx-font-weight-semibold"
                 *ngIf="active === 'alwaysActive'">{{"cookie.settings.tabs.technicalNecessaryCookies.alwaysActive" | translate}}</p>
              <nx-switcher *ngIf="id === 'cookiesForStatisticUsage'" [nxBig]="true"
                           [(ngModel)]="enableTracking" name="switcher"
              >
                {{enableTracking ? "Aktiv" : "Inaktiv"}}
              </nx-switcher>
              <nx-switcher *ngIf="id === 'cookiesForMarketingUsage'" [nxBig]="true"
                           [(ngModel)]="enableMarketing" name="switcher"
              >
                {{enableMarketing ? "Aktiv" : "Inaktiv"}}
              </nx-switcher>
            </div>
          </div>
          <h4 class="u-text-align-left margin-top-32px-mobile" nxHeadline="subsection-small"
              *ngIf="!active">
            {{title}}
          </h4>
          <p nxCopytext="large">
            {{info}}
          </p>
        </div>
      </div>
    </div>
    <div nxModalActions>
      <button id="cy-cookie-settings-save"
              class="nx-margin-top-m nx-margin-bottom-m u-padding-sm nx-margin-right-xs"
              nxButton="small secondary" type="button"
              (click)="saveSelected()">
        {{"cookie.settings.buttons.accept-some" | translate}}
      </button>
      <button id="cy-cookie-settings-save-all"
              class="nx-margin-top-m nx-margin-bottom-m u-padding-sm"
              nxButton="small primary" type="button"
              (click)="acceptAllCookiesAndCloseModal()">
        {{"cookie.settings.buttons.accept-all" | translate}}
      </button>
    </div>
  </ng-template>
</div>
